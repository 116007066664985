import MatchingEditView from './MatchingEditView';
import MatchingReadOnly from './MatchingReadOnly';

const Matching = props => {
  const { questionNode } = props;

  return questionNode?.qtiModel?.isInEditView ? <MatchingEditView {...props} /> : <MatchingReadOnly {...props} />;
};

export default Matching;
