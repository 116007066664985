import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAppContext } from '../../context/AppContext';
import { METADATA_OPTIONS } from 'common/constants/metadata-constants';
import './QuestionBanks.css';

const QUESTION_TYPES = Object.freeze(['TF', 'MC', 'FIB', 'MT', 'MR', 'ES']);

function Advancedsearch({ searchSelection, closeDropdown, onAdvancedSearch }) {
  const { userMetadata } = useAppContext();
  const [userSelection, setUserSelection] = useState(searchSelection);
  const filterByValueRef = useRef();

  /**
   * Initializes the selected options with the initial options.
   */
  useEffect(() => {
    setUserSelection(searchSelection);
  }, [searchSelection]);

  /**
   * Focuses the filterByValue input field when the filterBy state changes.
   */
  useEffect(() => {
    if (userSelection.filterBy && !userSelection.filterByValue && filterByValueRef.current) {
      filterByValueRef.current.focus();
    }
  }, [userSelection?.filterBy]);

  /**
   * Toggles the selection of an option.
   *
   * @param {string} option - The option to toggle
   */
  const toggleOption = option => {
    const { questionTypes } = userSelection;
    if (questionTypes.includes(option)) {
      setUserSelection({
        ...userSelection,
        questionTypes: userSelection.questionTypes.filter(item => item !== option),
      });
    } else {
      setUserSelection({
        ...userSelection,
        questionTypes: [...userSelection.questionTypes, option],
      });
    }
  };

  /**
   * Handles the search action.
   */
  const handleSearch = () => {
    onAdvancedSearch(userSelection);
  };

  /**
   * Handles the cancel action.
   */
  const handleCancel = () => {
    // reset back to context state filters
    setUserSelection(searchSelection);
    closeDropdown();
  };

  /**
   * Handles the filter by change event.
   *
   * @param {object} event - The change event
   */
  const handleFilterByChange = event => {
    setUserSelection({
      ...userSelection,
      filterBy: event.target.value,
      filterByValue: '',
    });
  };

  /**
   * Handles the filter by value change event.
   *
   * @param {object} event - The change event
   */
  const handleFilterByValueChange = event => {
    setUserSelection({ ...userSelection, filterByValue: event.target.value });
  };

  /**
   * Checks if the search is enabled based on the selected options and filter values.
   *
   * @returns {boolean} True if the search is enabled, false otherwise.
   */
  const isSearchEnabled = useMemo(() => {
    const { filterBy, filterByValue, questionTypes } = userSelection;
    if (questionTypes && questionTypes.length) {
      return filterBy ? filterByValue : true;
    } else {
      return filterBy ? filterByValue : false;
    }
  }, [userSelection]);

  const { filterBy, filterByValue, questionTypes } = userSelection;
  return (
    <div className="advanced-search-container-fluid bodyas-container">
      <span className="type-label">
        <FormattedMessage id="message.type" />
      </span>
      <Button className="close-button" variant="link" onClick={handleCancel} aria-label="close" title="Close">
        <i className="bi bi-x-lg"></i>
      </Button>
      <div className="advanced-search-options">
        <div className="advanced-button-options">
          <div className="buttons-container">
            {QUESTION_TYPES.map(type => (
              <Button
                size="sm"
                key={type}
                variant={questionTypes.includes(type) ? 'primary' : 'secondary'}
                onClick={() => toggleOption(type)}
              >
                <FormattedMessage id={`questionTypes.${type}`} />
              </Button>
            ))}
          </div>
        </div>
        <div>
          <>
            <div className="d-flex align-items-center">
              <span className="ms-2" tabIndex="0">
                <b>
                  <FormattedMessage id="message.advancedsearchMetadata" />
                </b>
              </span>
            </div>
            <div className="metadata-container">
              <div className="metadata-fields-container">
                <Row className="m-2">
                  <Col xs={12}>
                    <Form.Group className="meta-data-filter">
                      <Form.Select
                        style={{ height: '34px' }}
                        aria-label="Filter By"
                        className=""
                        size="md"
                        name="filterBy"
                        value={filterBy}
                        onChange={handleFilterByChange}
                      >
                        <option value="" className="font-arial">
                          <FormattedMessage id="filterBy" />
                        </option>

                        {userMetadata.map(metadata => (
                          <option key={metadata} value={metadata} className="font-arial">
                            <FormattedMessage id={`metadata.${metadata}`} />
                          </option>
                        ))}
                      </Form.Select>

                      {filterBy === METADATA_OPTIONS.DIFFICULTY ? (
                        <Form.Select
                          ref={filterByValueRef}
                          style={{ height: '34px' }}
                          aria-label="difficulty"
                          size="md"
                          name="difficulty"
                          value={filterByValue}
                          onChange={handleFilterByValueChange}
                        >
                          <option value="" className="font-arial">
                            <FormattedMessage id="chooseLevel" />
                          </option>
                          <option value="Esy" className="font-arial">
                            <FormattedMessage id="difficultyEasy" />
                          </option>
                          <option value="Mod" className="font-arial">
                            <FormattedMessage id="difficultyMedium" />
                          </option>
                          <option value="Dif" className="font-arial">
                            <FormattedMessage id="difficultyHard" />
                          </option>
                        </Form.Select>
                      ) : (
                        <Form.Control
                          ref={filterByValueRef}
                          style={{ height: '34px' }}
                          aria-label={filterBy}
                          type="text"
                          placeholder="Enter the filter term"
                          className=""
                          name={filterBy}
                          value={filterByValue}
                          onChange={handleFilterByValueChange}
                          disabled={filterBy === ''} // Disable if no metadata is selected
                        />
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        </div>
        <div className="custom-divider"></div>

        <div className="advanced-action-buttons">
          <Button variant="secondary" onClick={handleCancel}>
            <FormattedMessage id="message.cancel" />
          </Button>
          <Button onClick={handleSearch} disabled={!isSearchEnabled}>
            <FormattedMessage id="message.search" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Advancedsearch;
