import { useMemo } from 'react';
import DOMPurify from 'dompurify';
import ReadOnlyActionButtons from '../ReadOnlyActionButtons';
import { sanitizedData } from 'common/utils';
import { getShuffledOptions } from './matching-utils';
import '../questions.css';

const MatchingReadOnly = props => {
  const {
    questionNode,
    questionNodeIndex,
    showActionButtons = false,
    shuffleOptions = false,
    onEdit,
    onDelete,
  } = props;

  const Caption = questionNode.qtiModel ? questionNode.qtiModel.Caption : '';
  const Options = useMemo(() => {
    let options = questionNode.qtiModel ? questionNode.qtiModel.Options : [{ option: '', matchingOption: '' }];

    if (shuffleOptions) {
      options = getShuffledOptions(options);
    }

    return options;
  }, [shuffleOptions]);

  return (
    <div className="d-flex align-items-center question-container">
      <div className="flex-grow-1 d-flex align-items-center flex-wrap">
        <div className="d-flex align-items-start gap-2 w-100">
          {questionNodeIndex !== undefined && <div>{questionNodeIndex + 1})</div>}
          <div className="question-content flex-grow-1" dangerouslySetInnerHTML={sanitizedData(Caption)}></div>
        </div>

        <div className="question-answers w-100 gap-2">
          {Options.map((option, index) => (
            <div key={index} className="question-answer-option w-100">
              <div className="text-section d-flex flex-wrap w-100">
                <span
                  className="w-50 pr-4 d-flex align-items-start gap-1"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      `A${index + 1}) <div class="overflow-wrap-anywhere flex-grow-1">${option.option}</div>`
                    ),
                  }}
                ></span>
                <span
                  className="w-50 pl-4  d-flex align-items-start gap-1"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      `B${index + 1}) <div class="overflow-wrap-anywhere flex-grow-1">${option.matchingOption}</div>`
                    ),
                  }}
                ></span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {showActionButtons && <ReadOnlyActionButtons index={questionNodeIndex} onEdit={onEdit} onDelete={onDelete} />}
    </div>
  );
};

export default MatchingReadOnly;
