import { Navigate } from 'react-router-dom';
import WSLayout from 'workspace/components/layout/WSLayout';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import ManageQuestionsProvider from 'workspace/providers/manage-questions/ManageQuestionsProvider';

const ProtectedRoutes = () => {
  const { token } = useAuth();

  return token ? (
    <ManageQuestionsProvider>
      <WSLayout />
    </ManageQuestionsProvider>
  ) : (
    <Navigate to="login" />
  );
};

export default ProtectedRoutes;
