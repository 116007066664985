import { Outlet } from 'react-router-dom';
import WSTabs from 'workspace/components/layout/WSTabs';
import ProfileModal from '../ProfileModal/ProfileModal';
import Header from 'common/components/layout/Header';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import './layout.css';

const WSLayout = () => {
  const { userData } = useAuth();
  return (
    <>
      <div className="ws-root">
        <Header hideHelp workspaceName={userData.workspaceName}>
          <ProfileModal />
        </Header>
        <WSTabs />

        <div className="container-resource flex-grow-1 overflow-auto">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default WSLayout;
