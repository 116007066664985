import React from 'react';
import { QUESTION_COMPONENTS } from 'common/components/questions/question-mapping';
import { useAppContext } from 'test-builder/context/AppContext';

const QuestionMapper = props => {
  const { testTabsDispatch, userMetadata } = useAppContext();

  const onSubmit = payload => {
    // Dispatch event to update question inside selected test
    testTabsDispatch({ type: 'UPDATE_QUESTION_INSIDE_SELETED_TEST', payload });
  };

  const onEdit = index => {
    // Dispatch event to edit question inside selected test
    testTabsDispatch({ type: 'EDIT_QUESTION_CLICK', payload: { index } });
  };

  const onDelete = index => {
    // Dispatch event to delete question inside selected test
    testTabsDispatch({ type: 'DELETE_QUESTION_CLICK', payload: { index } });
  };

  return React.createElement(QUESTION_COMPONENTS[props.questionNode.quizType], {
    ...props,
    userMetadata,
    onSubmit,
    onEdit,
    onDelete,
  });
};

export default QuestionMapper;
