import { useState } from 'react';
import { Button, Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useAuth } from '../../context/AuthContext';
import SettingsModal from './SettingsModal';
import './profile.css';

const Profile = () => {
  const { user, logout } = useAuth();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [settingsModalLoaded, setSettingsModalLoaded] = useState(false); // Flag to indicate if modal content has been loaded

  const handleSettingsModalOpen = () => {
    setShowSettingsModal(true);
  };

  const handleSettingsModalClose = () => {
    setShowSettingsModal(false);
  };

  // Function to load modal content and set flag
  const loadSettingsModalContent = () => {
    setSettingsModalLoaded(true);
  };

  return (
    <>
      <Dropdown id="nav-dropdown" as={NavItem} align={'end'} className="profile-dropdown">
        <Dropdown.Toggle as={NavLink}>
          <i className="bi bi-person-fill"></i> {user.firstname} {user.lastname}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="user-details d-inline-flex flex-column justify-content-center align-items-center px-4 py-1">
            <span className="d-inline-block text-truncate w-100" title={user.firstname + ' ' + user.lastname}>
              <strong>
                {user.firstname} {user.lastname}
              </strong>
            </span>
            <span className="d-inline-block text-truncate w-100" title={user.email}>
              {user.email}
            </span>
          </div>
          <Dropdown.Divider />
          <div className="settings-and-sign-out">
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant="primary"
                className="button-setting"
                onClick={() => {
                  handleSettingsModalOpen();
                  loadSettingsModalContent();
                }}
              >
                <FormattedMessage id="profile.setting" />
              </Button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Button variant="primary" className="button-signout" onClick={logout}>
                <FormattedMessage id="profile.signout" />
              </Button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {showSettingsModal && settingsModalLoaded && (
        <SettingsModal show={showSettingsModal} handleClose={handleSettingsModalClose} />
      )}
    </>
  );
};

export default Profile;
