import { useAppContext } from '../../context/AppContext';
import Loader from '../../../common/components/loader/Loader';

const ApplicationLoader = () => {
  const {
    loader: { isLoading },
  } = useAppContext();

  return <Loader show={isLoading} />;
};

export default ApplicationLoader;
