import MultipleResponseEditView from './MultipleResponseEditView';
import MultipleResponseReadOnly from './MultipleResponseReadOnly';

const MultipleResponse = props => {
  const { questionNode } = props;

  return questionNode?.qtiModel?.isInEditView ? (
    <MultipleResponseEditView {...props} />
  ) : (
    <MultipleResponseReadOnly {...props} />
  );
};

export default MultipleResponse;
