import { useState } from 'react';
import { DEFAULT_EXTENDED_METADATA, ORIENTATION, QUESTION_TYPES } from 'common/constants/question-constants';

const initialFormatAndExtendedMetadata = questionNode => {
  const {
    qtiModel: { extendedMetadata, orientation, expectedLines },
  } = questionNode;
  const initialData = {
    ...DEFAULT_EXTENDED_METADATA,
    ...extendedMetadata,
  };

  if (
    questionNode.quizType === QUESTION_TYPES.MultipleChoice ||
    questionNode.quizType === QUESTION_TYPES.MultipleResponse ||
    questionNode.quizType === QUESTION_TYPES.TrueFalse
  ) {
    initialData.orientation = orientation ?? ORIENTATION.VERTICAL;
  }

  if (questionNode.quizType === QUESTION_TYPES.Essay) {
    initialData.expectedLines = expectedLines ?? '0';
  }

  return initialData;
};

const useFormatAndExtendedMetadata = questionNode => {
  const [formatAndExtendedMetadata, setFormatAndExtendedMetadata] = useState(
    initialFormatAndExtendedMetadata(questionNode)
  );

  return [formatAndExtendedMetadata, setFormatAndExtendedMetadata];
};

export default useFormatAndExtendedMetadata;
