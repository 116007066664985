import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Editor, EditorProvider, Toolbar } from 'lib/rte';
import EditViewActionButtons from '../EditViewActionButtons';
import FormatAndAddExtendedMetadataSection from '../FormatAndAddExtendedMetadataSection';
import useFormatAndExtendedMetadata from '../hooks/useFormatAndExtendedMetadata';

const EssayEditView = ({ questionNode, questionNodeIndex, userMetadata, onSubmit, onDelete }) => {
  const { Caption, RecommendedAnswer } = questionNode.qtiModel;
  const intl = useIntl();

  const [questionData, setQuestionData] = useState({
    Caption: Caption ?? '',
    RecommendedAnswer: RecommendedAnswer ?? '',
  });

  const [formatAndExtendedMetadata, setFormatAndExtendedMetadata] = useFormatAndExtendedMetadata(questionNode);

  const handleChange = e => {
    const { name, value } = e.target || e;

    setQuestionData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="m-2 border rounded p-3 bg-light">
      <EditorProvider>
        <Toolbar />
        <Form className="editmode paddingright10">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="mb-1">
              <b>{intl.formatMessage({ id: 'label.essay.title' })}</b>
            </Form.Label>

            <Editor
              className="mb-2"
              placeholder={intl.formatMessage({ id: 'placeholder.essay.caption' })}
              value={questionData.Caption}
              onChange={value => handleChange({ name: 'Caption', value })}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label className="mb-1">
              <b>{intl.formatMessage({ id: 'label.essay.answer' })}</b>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="RecommendedAnswer"
              value={questionData.RecommendedAnswer}
              onChange={handleChange}
            />
          </Form.Group>

          <FormatAndAddExtendedMetadataSection
            data={formatAndExtendedMetadata}
            setData={setFormatAndExtendedMetadata}
            userMetadata={userMetadata}
          />
        </Form>

        <EditViewActionButtons
          index={questionNodeIndex}
          questionNode={questionNode}
          questionData={questionData}
          formatAndExtendedMetadata={formatAndExtendedMetadata}
          onSubmit={onSubmit}
          onDelete={onDelete}
        />
      </EditorProvider>
    </div>
  );
};

export default EssayEditView;
