import React from 'react';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfirmDialog } from 'common/components/ConfirmationDialog';
import { formatDate } from 'common/utils';
import { BOOK_STATUS, ROLES } from 'workspace/constants';
import { useAuth } from 'workspace/providers/auth/AuthContext';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <i className="fa-solid fa-ellipsis-vertical three-dots-icon"></i>
  </a>
));

const BookCard = ({ book, handleActions }) => {
  const intl = useIntl();
  const { userData } = useAuth();
  const { showConfirmDialog } = useConfirmDialog();
  const bookTitle = `${book.title}`;

  // Tooltip messages for published/republished states
  const publishedTooltip =
    book.status === BOOK_STATUS.PUBLISHED
      ? intl.formatMessage({ id: 'book.published.tooltip' })
      : book.status === BOOK_STATUS.REPUBLISHED
        ? intl.formatMessage({ id: 'book.republished.tooltip' })
        : '';

  const handleBookActions = (action, book) => {
    switch (action) {
      case 'details':
      case 'open':
      case 'edit':
        handleActions(action, book);
        break;
      case BOOK_STATUS.PUBLISHED:
      case BOOK_STATUS.REPUBLISHED:
      case BOOK_STATUS.UNPUBLISHED:
        handlePublishOrRepublish(action, book);
        break;
      case 'delete':
        showConfirmDialog({
          title: intl.formatMessage({ id: 'ws.modal.delete.confirmation.title' }),
          message: (
            <span>
              {intl.formatMessage(
                { id: 'ws.modal.delete.book.confirmation.message' },
                {
                  bookTitle: <strong>{bookTitle}</strong>,
                }
              )}
            </span>
          ),
          confirmText: intl.formatMessage({ id: 'message.delete' }),
          cancelText: intl.formatMessage({ id: 'message.cancel' }),
          onConfirm: () => {
            handleActions(action, book);
          },
        });
        break;
      default:
        break;
    }
  };

  const handlePublishOrRepublish = (action, book) => {
    var publishAction;
    if (action === BOOK_STATUS.PUBLISHED) {
      publishAction = intl.formatMessage({ id: 'ws.publish' });
    } else if (action === BOOK_STATUS.REPUBLISHED) {
      publishAction = intl.formatMessage({ id: 'ws.republish' });
    } else if (action === BOOK_STATUS.UNPUBLISHED) {
      publishAction = intl.formatMessage({ id: 'ws.unpublish' });
    }
    var publishActionBody = publishAction.toLowerCase();
    const title = intl.formatMessage({ id: 'ws.modal.publish.confirmation.title' }, { publishAction });
    const message = intl.formatMessage(
      { id: 'ws.modal.publish.confirmation.body' },
      {
        title: <strong>{book?.title}</strong> || '',
        publishActionBody,
      }
    );
    const confirmText = intl.formatMessage({ id: 'message.confirm' });
    const cancelText = intl.formatMessage({ id: 'message.cancel' });

    showConfirmDialog({
      title,
      message,
      confirmText,
      cancelText,
      onConfirm: () => {
        handleActions(action, book, action);
      },
    });
  };

  return (
    <Card className="book-custom-card mx-auto">
      <Card.Body className="book-card-body">
        <div className="book-details">
          {/* Show tick mark for published books with tooltip */}
          {book.status === BOOK_STATUS.PUBLISHED && (
            <i
              className="fa-solid fa-check book-published-icon"
              title={publishedTooltip} // Tooltip based on book status
            ></i>
          )}
          {book.status === BOOK_STATUS.REPUBLISHED && (
            <i
              className="fa-solid fa-check-double book-published-icon"
              title={publishedTooltip} // Tooltip based on book status
            ></i>
          )}
          <Dropdown className="three-dots">
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu size="sm" title="" align="end">
              <Dropdown.Item onClick={() => handleBookActions('details', book)}>
                <FormattedMessage id="ws.details" />
              </Dropdown.Item>
              {/* Show delete option only for unpublished books */}
              {(book.status === BOOK_STATUS.NEW || book.status === BOOK_STATUS.UNPUBLISHED) && (
                <Dropdown.Item onClick={() => handleBookActions('delete', book)}>
                  <FormattedMessage id="message.delete" />
                </Dropdown.Item>
              )}
              {/* Show unpublish option only for published books */}
              {(book.status === BOOK_STATUS.PUBLISHED || book.status === BOOK_STATUS.REPUBLISHED) &&
                userData.role === ROLES.SUPER_ADMIN && (
                  <Dropdown.Item onClick={() => handleBookActions(BOOK_STATUS.UNPUBLISHED, book)}>
                    <FormattedMessage id="ws.unpublish" />
                  </Dropdown.Item>
                )}
            </Dropdown.Menu>
          </Dropdown>

          <div className="book-created-by" title={book.creatorName}>
            <FormattedMessage id="ws.createdby" />: {book.creatorName || <FormattedMessage id="ws.book.na" />}
          </div>
          <div className="book-publisher">
            <FormattedMessage id="ws.publisher" />: {book.publisher}
          </div>
          <div className="book-created-date">
            <FormattedMessage id="ws.createddate" />:{formatDate(book.created) || <FormattedMessage id="ws.book.na" />}
          </div>
        </div>
        <hr />
        <div className="bookTitleText">
          <Card.Text className="book-card-text">{book.title}</Card.Text>
          <Card.Text className="book-card-discipline">
            <FormattedMessage id="ws.discipline" />: {book.discipline}
          </Card.Text>
        </div>
        <div className="button-group">
          {/* Show edit button */}
          <Button variant="primary" className="mr-2" onClick={() => handleBookActions('edit', book)}>
            <FormattedMessage id="message.edit" />
          </Button>
          <Button variant="primary" className="mr-2" onClick={() => handleBookActions('open', book)}>
            <FormattedMessage id="ws.open" />
          </Button>
          {/* Conditional rendering of publish/republish button */}
          <Button
            variant="primary"
            onClick={() =>
              handleBookActions(
                book.status === BOOK_STATUS.PUBLISHED || book.status === BOOK_STATUS.REPUBLISHED
                  ? BOOK_STATUS.REPUBLISHED
                  : BOOK_STATUS.PUBLISHED,
                book
              )
            }
          >
            <FormattedMessage
              id={`${book.status === BOOK_STATUS.PUBLISHED || book.status === BOOK_STATUS.REPUBLISHED ? 'ws.republish' : 'ws.publish'}`}
            />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BookCard;
