import { initialData } from './ManageQuestionsProvider';
import { findSelectedFolder } from './utils';

export const MANAGE_QUESTION_ACTIONS = {
  UPDATE_SELECTED_BOOK: 'UPDATE_SELECTED_BOOK',
  UPDATE_SELECTED_FOLDER: 'UPDATE_SELECTED_FOLDER',

  ADD_QUESTION: 'ADD_QUESTION',
  SAVE_QUESTIONS: 'SAVE_QUESTIONS',
  CLEAR_ACTIVE_QUESTIONS: 'CLEAR_ACTIVE_QUESTIONS',
  VIEW_QUESTION_CLICK: 'VIEW_QUESTION_CLICK',
  EDIT_QUESTION_CLICK: 'EDIT_QUESTION_CLICK',
  DELETE_QUESTION_CLICK: 'DELETE_QUESTION_CLICK',

  UPDATE_ROOT_LEVEL_FOLDERS_QUESTIONS: 'UPDATE_ROOT_LEVEL_FOLDERS_QUESTIONS',
  ADD_FOLDER: 'ADD_FOLDER',
  DELETE_FOLDER: 'DELETE_FOLDER',
  UPDATE_FOLDER_NAME: 'UPDATE_FOLDER_NAME',
  UPDATE_FOLDERS_TREE_DATA_ARRAY: 'UPDATE_FOLDERS_TREE_DATA_ARRAY',
  INSERT_FOLDERS_AND_QUESTIONS: 'INSERT_FOLDERS_AND_QUESTIONS',

  REARRANGE_FOLDER: 'REARRANGE_FOLDER',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const bookQuestionsReducer = (state, action) => {
  const { type, payload } = action;
  const { treeData, activeQuestions } = state;

  switch (type) {
    case MANAGE_QUESTION_ACTIONS.UPDATE_SELECTED_BOOK:
      return { ...state, selectedBook: payload };

    case MANAGE_QUESTION_ACTIONS.UPDATE_SELECTED_FOLDER:
      return { ...state, selectedFolder: payload };

    case MANAGE_QUESTION_ACTIONS.UPDATE_ROOT_LEVEL_FOLDERS_QUESTIONS:
      return { ...state, treeData: payload };

    case MANAGE_QUESTION_ACTIONS.ADD_FOLDER: {
      const selectedFolder = state.selectedFolder
        ? findSelectedFolder(treeData.children.folders, state.selectedFolder)
        : treeData;

      selectedFolder.children = selectedFolder.children || { folders: [], questions: [] };

      selectedFolder.children.folders = [...selectedFolder.children.folders, payload];

      // const folders = [...treeData.children.folders, payload];
      selectedFolder.children.folders.sort((a, b) => b.sequence - a.sequence);

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case MANAGE_QUESTION_ACTIONS.INSERT_FOLDERS_AND_QUESTIONS: {
      const { treeData } = state;
      const { parentId, folders, questions } = payload;

      const selectedFolder = findSelectedFolder(treeData.children.folders, parentId);
      if (selectedFolder) {
        selectedFolder.children = {
          folders,
          questions,
        };
      }

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case MANAGE_QUESTION_ACTIONS.DELETE_FOLDER: {
      const { folder } = payload;

      const selectedFolder = findSelectedFolder(treeData.children.folders, folder.id);

      if (selectedFolder) {
        const parentFolder = findSelectedFolder(treeData.children.folders, selectedFolder.parentId);
        if (parentFolder) {
          parentFolder.children.folders = parentFolder.children.folders.filter(f => f.guid !== folder.id);
        } else {
          treeData.children.folders = treeData.children.folders.filter(f => f.guid !== folder.id);
        }
      }

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case MANAGE_QUESTION_ACTIONS.UPDATE_FOLDER_NAME: {
      const { folder, title } = payload;

      const selectedFolder = findSelectedFolder(treeData.children.folders, folder.id);

      if (selectedFolder) {
        selectedFolder.title = title;
      }

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case MANAGE_QUESTION_ACTIONS.UPDATE_FOLDERS_TREE_DATA_ARRAY:
      return { ...state, treeDataAsArray: payload };

    case MANAGE_QUESTION_ACTIONS.REARRANGE_FOLDER: {
      const { treeData } = state;
      const { dragSource, dropTarget, updatedFolder } = payload;

      // Find subfolders at parent folder
      const subFoldersAtParent =
        dragSource.parent === treeData.guid
          ? treeData.children.folders
          : findSelectedFolder(treeData.children.folders, dragSource.parent).children.folders;

      // Find Old index of dragged folder
      const oldSourceFolderIndex = subFoldersAtParent.findIndex(folder => folder.guid === dragSource.id);
      // Delete folder from old folder
      const [draggedFolder] = subFoldersAtParent.splice(oldSourceFolderIndex, 1);

      // Update sequence of dragged folder
      draggedFolder.sequence = updatedFolder.sequence;
      draggedFolder.parentId = updatedFolder.parentId;

      // Find Sub folders at destination folder
      let subFoldersAtDestination;
      if (dropTarget) {
        const destFolder = findSelectedFolder(treeData.children.folders, dropTarget.id);
        const children = destFolder.children || {};
        subFoldersAtDestination = children.folders || [];
      } else {
        subFoldersAtDestination = treeData.children.folders;
      }

      // Add dragged folder at destination and update the folders based on sequence
      subFoldersAtDestination.push(draggedFolder);
      subFoldersAtDestination.sort((a, b) => b.sequence - a.sequence);

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case MANAGE_QUESTION_ACTIONS.ADD_QUESTION:
      return { ...state, activeQuestions: [...activeQuestions, payload] };

    case MANAGE_QUESTION_ACTIONS.VIEW_QUESTION_CLICK: {
      const { index, question } = payload;

      const questions = [...activeQuestions];
      questions[index] = question;

      return { ...state, activeQuestions: questions };
    }

    case MANAGE_QUESTION_ACTIONS.EDIT_QUESTION_CLICK: {
      activeQuestions[payload].qtiModel.isInEditView = true;
      const questions = [...activeQuestions];

      return { ...state, activeQuestions: questions };
    }

    case MANAGE_QUESTION_ACTIONS.DELETE_QUESTION_CLICK: {
      const questions = [...activeQuestions];
      questions.splice(payload, 1);

      return { ...state, activeQuestions: questions };
    }

    case MANAGE_QUESTION_ACTIONS.SAVE_QUESTIONS: {
      const { questions, folderId } = payload;
      const selectedFolder = findSelectedFolder(treeData.children.folders, folderId);
      if (selectedFolder) {
        selectedFolder.children = selectedFolder.children || { folders: [], questions: [] };
        selectedFolder.children.questions = [...selectedFolder.children.questions, ...questions];
      } else {
        treeData.children.questions = [...treeData.children.questions, ...questions];
      }

      return {
        ...state,
        activeQuestions: [],
        treeData: Object.assign({}, treeData),
      };
    }

    case MANAGE_QUESTION_ACTIONS.CLEAR_ACTIVE_QUESTIONS:
      return {
        ...state,
        activeQuestions: [],
      };

    case MANAGE_QUESTION_ACTIONS.CLEAR_STATE:
      return Object.assign({}, initialData);

    default:
      return state;
  }
};
