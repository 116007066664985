import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Form, Button } from 'react-bootstrap';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import { login } from 'workspace/api/login.service';
import { toastify } from 'common/components/Toastify';
import { useLoader } from 'common/providers/LoaderProvider';
import Password from 'common/components/form/Password';
import logo from '../../../assets/images/logo.png';
import './login-page.css';

const LoginPage = () => {
  const { showLoader, hideLoader } = useLoader();
  const { loginSuccessful } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const intl = useIntl();

  useEffect(() => {
    document.title = 'Pearson Test Builder';
  }, []);

  const handleLogin = async event => {
    event.preventDefault();

    if (!username || !password) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.LoginWarning' }));
      return;
    }

    try {
      showLoader();
      const data = await login(username, password);
      loginSuccessful(data);
    } catch (error) {
      let message = error.message;
      if (error.status === 403) {
        message = intl.formatMessage({ id: 'error.invalidUsernamePassword' });
      }
      toastify.showErrorToast(message);
    } finally {
      hideLoader();
    }
  };

  return (
    <div className="ws-login-screen d-flex justify-content-center align-items-center h-100">
      <Card className="login-card">
        <div className="pearson-logo">
          <img src={logo} alt="pearson logo" />
        </div>
        <Card.Body>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label className="formlabel-username">
                <FormattedMessage id="userNameLabel" />
              </Form.Label>
              <Form.Control
                className="textbox-username"
                type="text"
                placeholder="Enter Username"
                value={username}
                onChange={e => setUsername(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className="password" controlId="formPassword">
              <Form.Label className="formlabel-password">
                <FormattedMessage id="login.password" />
              </Form.Label>
              <Password
                required
                className="textbox-password"
                placeholder="Enter Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Form.Group>

            <Button onClick={handleLogin} variant="primary" type="submit" className="login-button">
              <FormattedMessage id="login.signin" />
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default LoginPage;
