import $ from 'jquery';

/**
 * Appends nodes to an element, replacing any existing content.
 * @param {jQuery} elm - The element to append nodes to.
 * @param {string} val - The HTML string to parse and append.
 * @returns {jQuery} The updated element.
 */
export const appendNodes = (elm, val) => {
  elm.empty();
  const nodes = $.parseXML(`<dummy>${val}</dummy>`).childNodes[0];
  while (nodes.childNodes.length > 0) {
    elm.append($(nodes.childNodes[0]));
  }

  return elm;
};

/**
 * Appends HTML nodes to an element without clearing existing content.
 * @param {jQuery} elm - The element to append nodes to.
 * @param {string} val - The HTML string to parse and append.
 * @returns {jQuery} The updated element.
 */
export const appendHTMLNodes = (elm, val) => {
  const nodes = $.parseXML(`<dummy>${val}</dummy>`).childNodes[0];
  while (nodes.childNodes.length > 0) {
    elm.append($(nodes.childNodes[0]));
  }

  return elm;
};

/**
 * Serializes a QTI node to an XML string.
 * @param {jQuery} qtiNode - The QTI node to serialize.
 * @returns {string} The serialized XML string.
 */
export const getSerializedXML = qtiNode => {
  let serializedText = '';
  const xmlChildren = qtiNode.eq(0).get(0).childNodes;

  xmlChildren.forEach(children => {
    serializedText += children.nodeType == 4 ? children.textContent : new XMLSerializer().serializeToString(children);
  });

  return serializedText;
};
