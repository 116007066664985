import { createContext, useContext } from 'react';

const QuestionsContext = createContext(undefined);

export const useQuestionsContext = () => {
  const context = useContext(QuestionsContext);

  if (context === undefined) {
    throw new Error('useQuestionsContext must be used within a EditorProvider');
  }

  return context;
};

export default QuestionsContext;
