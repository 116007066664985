import axios from 'axios';
import { publish } from 'common/utils/events';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(config => {
  const isLoginEndpoint = config.url.includes('login');

  if (isLoginEndpoint) {
    // Remove Authorization header for login endpoint
    delete config.headers['Authorization'];
  } else {
    // Set Authorization header with Bearer token
    const token = localStorage.getItem('wstoken');
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      publish('ws:auth:logout');

      // Return a resolved promise to stop .then() and .catch() from firing
      return new Promise(() => {}); // An unresolved promise effectively stops the chain
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
