import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfirmDialog } from 'common/components/ConfirmationDialog';
import { DEFAULT_QUESTION_TEMPLATES } from 'common/utils/question_templates';
import { useManageQuestionsContext } from 'workspace/providers/manage-questions/ManageQuestionsProvider';
import QuestionsListContainer from './QuestionsListContainer';
import FoldersTreeView from './FoldersTreeView';
import ImportQuestion from './ImportQuestion';
import AddFolderWidget from 'common/components/AddFolderWidget/AddFolderWidget';
import { toastify } from 'common/components/Toastify';
import { isAllQuestionsAreInViewMode, transformQuestionTemplateToQuestion } from 'common/utils/questions-utils';
import { deepClone } from 'common/utils';
import './manage-questions.css';

const ManageQuestionsTab = props => {
  const { selectedBook, hideBooks } = props;
  const { showConfirmDialog } = useConfirmDialog();
  const {
    treeData,
    activeQuestions,
    updateSelectedBook,
    clearManageQuestionsState,
    addQuestionToTheList,
    addNewFolder,
    updateFolderName,
    deleteFolderFromBook,
    saveQuestionsToFolder,
  } = useManageQuestionsContext();
  const intl = useIntl();
  const bookOpenedTTT = intl.formatMessage({ id: 'ws.openedbook' }, { bookname: selectedBook.title });

  const [folderToEdit, setFolderToEdit] = useState();

  useEffect(() => {
    if (selectedBook) {
      updateSelectedBook(selectedBook);
    }
  }, [selectedBook]);

  const handleBack = () => {
    clearManageQuestionsState();
    hideBooks();
  };

  const handleAddQuestion = question => {
    let clonedQuestion = deepClone(question);
    const newQuestion = transformQuestionTemplateToQuestion(clonedQuestion);
    addQuestionToTheList(newQuestion);
  };

  const handleDeleteFolderClick = folder => {
    showConfirmDialog({
      title: intl.formatMessage({ id: 'ws.modal.delete.confirmation.title' }),
      message: (
        <>
          <i className="fa-solid fa-circle-question"></i>
          &nbsp;
          <FormattedMessage
            id="ws.modal.delete.folder.confirmation.message"
            values={{
              folderTitle: <strong>{folder.text}</strong>,
            }}
          />
        </>
      ),
      confirmText: intl.formatMessage({ id: 'message.delete' }),
      cancelText: intl.formatMessage({ id: 'message.cancel' }),
      onConfirm: async () => {
        await deleteFolderFromBook(folder);
      },
    });
  };

  const handleSaveFolderName = title => {
    const trimmedTitle = title.trim();
    if (!trimmedTitle) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.FolderNameCannotBeEmpty' }));
      setFolderToEdit(null);
      return;
    }

    if (folderToEdit) {
      updateFolderName(folderToEdit, trimmedTitle);
    } else {
      addNewFolder(trimmedTitle);
    }
    setFolderToEdit(null);
  };

  const handleFolderNameEditClick = folder => {
    setFolderToEdit(folder);
  };

  const handleSaveClick = async () => {
    if (!isAllQuestionsAreInViewMode(activeQuestions)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.noQuestionsOrNotInEditState' }));
      return;
    }

    // logic for saving questions into folder goes here...

    await saveQuestionsToFolder();
  };

  const { folders, questions } = treeData?.children || {};

  return (
    <div className="manage-questions-container">
      <div className="left-section">
        <div className="books-title-header">
          <i
            className="fa fa-arrow-circle-left back-arrow-icon"
            tabIndex="0"
            title="Back to book list"
            onClick={handleBack}
          ></i>
          <span className="selected-book-title">
            <div>
              <i className="fa fa-book-open openbook" aria-hidden="true"></i>
            </div>
            <div>{selectedBook.title}</div>
          </span>
        </div>
        <div className="folder-actions-container">
          <AddFolderWidget
            name={folderToEdit?.text}
            secondButton={<ImportQuestion />}
            onSave={handleSaveFolderName}
            onclose={() => setFolderToEdit(null)}
          />
        </div>
        <div className="flex-grow-1 overflow-y-auto">
          {folders?.length || questions?.length ? (
            <FoldersTreeView
              folderToEdit={folderToEdit}
              onFolderEditClick={handleFolderNameEditClick}
              onFolderDeleteClick={handleDeleteFolderClick}
            />
          ) : (
            <div className="manage-questions-instructions-container">
              <div className="flex-box" tabIndex="0">
                <i className="far fa-lightbulb light-orange" aria-label="bulb icon" tabIndex="0"></i>

                <div className="question-instructions-container">
                  <p className="bold-text">
                    <FormattedMessage id="ws.instructions.noQuestions1" />
                  </p>
                  <p className="bold-text">
                    <FormattedMessage id="ws.instructions.noQuestions2" />
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="right-section">
        <div className="questions-list-container">
          {DEFAULT_QUESTION_TEMPLATES.map((question, index) => (
            <Button
              variant="outline-primary"
              className="btn btn-primary add-question-button"
              size="sm"
              key={index}
              onClick={() => handleAddQuestion(question)}
            >
              <FormattedMessage id={`question.${question.quizType}`} />
            </Button>
          ))}
        </div>
        <div className="questions-save-action-contianer">
          <div className="create-questions">
            <FormattedMessage id="ws.create.questions" defaultMessage="Create Question" />
          </div>
          <Button className="color-black" variant="outline-light" onClick={handleSaveClick}>
            <i className="fa-solid fa-save"></i>&ensp;
            <FormattedMessage id="message.save" defaultMessage="Save" />
          </Button>
        </div>
        <div className="pt-3 flex-grow-1 overflow-auto">
          <QuestionsListContainer />
        </div>
      </div>
    </div>
  );
};

export default ManageQuestionsTab;
