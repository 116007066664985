import ReadOnlyActionButtons from '../ReadOnlyActionButtons';
import { sanitizedData } from 'common/utils';
import { ORIENTATION } from 'common/constants/question-constants';
import '../questions.css';

const MultipleChoiceReadOnly = props => {
  const { questionNode, questionNodeIndex, showActionButtons = false, hideAnswers = false, onEdit, onDelete } = props;

  const Caption = questionNode.qtiModel ? questionNode.qtiModel.Caption : '';
  const Options = questionNode.qtiModel ? questionNode.qtiModel.Options : ['', '', '', ''];
  const CorrectAnswer = questionNode.qtiModel ? questionNode.qtiModel.CorrectAnswer : -1;
  const orientation = questionNode.qtiModel ? questionNode.qtiModel.orientation : ORIENTATION.VERTICAL;

  return (
    <div className="d-flex align-items-center question-container">
      <div className="flex-grow-1 d-flex align-items-center flex-wrap">
        <div className="d-flex align-items-start gap-2 w-100">
          {questionNodeIndex !== undefined && <div>{questionNodeIndex + 1})</div>}
          <div className="question-content flex-grow-1" dangerouslySetInnerHTML={sanitizedData(Caption)}></div>
        </div>

        <div className={`question-answers w-100 ${orientation === ORIENTATION.HORIZONTAL ? 'horizontal-options' : ''}`}>
          {Options.map((value, index) => {
            return (
              <div key={index} className="question-answer-option">
                <div className="icon-section">
                  {!hideAnswers && CorrectAnswer == index ? (
                    <i className="bi bi-check" style={{ color: 'green' }}></i>
                  ) : (
                    <span className="icon-ml"></span>
                  )}
                </div>
                <div className="answer-option-text flex-grow-1">
                  <span>{String.fromCharCode(index + 'A'.charCodeAt(0))})</span>
                  <span className="answer flex-grow-1" dangerouslySetInnerHTML={sanitizedData(value)}></span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {showActionButtons && <ReadOnlyActionButtons index={questionNodeIndex} onEdit={onEdit} onDelete={onDelete} />}
    </div>
  );
};

export default MultipleChoiceReadOnly;
