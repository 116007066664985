import Header from 'common/components/layout/Header';
import Profile from './Profile';

const TBHeader = () => {
  return (
    <Header>
      <Profile />
    </Header>
  );
};

export default TBHeader;
