import TBHeader from './TBHeader';

const FullLayout = ({ children }) => {
  return (
    <>
      <TBHeader />
      <main>
        <div className="container-fluid body-container">{children}</div>
      </main>
    </>
  );
};
export default FullLayout;
