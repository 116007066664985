import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import './add-folder-widget.css';

const AddFolderWidget = ({ name, secondButton, onSave, onclose }) => {
  const [showTextBox, setShowTextBox] = useState();
  const [folderName, setFolderName] = useState('');

  useEffect(() => {
    if (name) {
      setFolderName(name);
      setShowTextBox(true);
    }
  }, [name]);

  const handleAddFolderClick = () => {
    setShowTextBox(true);
  };

  const handleSaveFolder = () => {
    onSave(folderName);
    setFolderName('');
    setShowTextBox(false);
  };

  const handleTextBoxClose = () => {
    setFolderName('');
    setFolderName('');
    setShowTextBox(false);
    onclose();
  };

  return (
    <div className="add-folder-widget">
      <div className="afw-button-container">
        <Button className="color-black" variant="outline-light" onClick={handleAddFolderClick}>
          <i className="fa-solid fa-plus"></i>&ensp;
          <FormattedMessage id="yourtests.addfolder" />
        </Button>
        {secondButton}
      </div>
      {showTextBox && (
        <div className="text-box d-flex align-items-center p-2">
          <div className="flex-grow-1 mr-2">
            <Form.Control
              type="text"
              placeholder="Enter folder name"
              value={folderName}
              onChange={e => setFolderName(e.target.value)}
              className="rounded ml-1 folderText"
            />
          </div>
          <div className="d-flex">
            <Button
              onClick={handleSaveFolder}
              className=""
              aria-label=""
              title="Check Mark"
              style={{ color: 'black', backgroundColor: 'white' }}
            >
              <i className="fa-solid fa-check"></i>
            </Button>
            <Button
              onClick={handleTextBoxClose}
              className="closebtn m1-2"
              aria-label=""
              title="Close"
              style={{ color: 'black', backgroundColor: 'white' }}
            >
              <i className="fa-solid fa-xmark"></i>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddFolderWidget;
