import EssayEditView from './EssayEditView';
import EssayReadOnly from './EssayReadOnly';

const Essay = props => {
  const { questionNode } = props;

  return questionNode?.qtiModel?.isInEditView ? <EssayEditView {...props} /> : <EssayReadOnly {...props} />;
};

export default Essay;
