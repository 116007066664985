import React, { useState } from 'react';
import { Col, Collapse, Form, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { ORIENTATION } from 'common/constants/question-constants';
import { ESSAY_QUESTION_SIZE_DATA, METADATA_OPTIONS } from 'common/constants/metadata-constants';

const FormatAndAddExtendedMetadataSection = ({ data, setData, userMetadata = [] }) => {
  const intl = useIntl();
  const [expanded, setExpanded] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target || e;

    setData({ ...data, [name]: value });
  };

  const handleDropdownChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setExpanded(!open);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <div className="pointer" onClick={() => setExpanded(!expanded)} onKeyDown={handleKeyDown} tabIndex="0">
          {expanded ? <i className="bi bi-caret-down-fill"></i> : <i className="bi bi-caret-right-fill"></i>}
        </div>
        <span className="ms-2" tabIndex="0">
          <b>
            <FormattedMessage id="message.formatAndAddMetadata" />
          </b>
        </span>
      </div>
      <Collapse key={expanded ? 'open' : 'closed'} in={expanded}>
        <div className="metadata-container">
          {data.orientation !== undefined && (
            <>
              <div className="mb-2">
                <span className="caption-metadata">
                  <FormattedMessage id="formatMultipleResponse" />
                </span>
                <span className="normal-text">
                  <FormattedMessage id="visibleInPrintViewMultipleResponse" />
                </span>
              </div>
              <div className="d-flex mc--orientation" tabIndex="0">
                <Form.Check
                  type="radio"
                  onChange={handleChange}
                  checked={data.orientation === ORIENTATION.HORIZONTAL}
                  className="mr-5"
                  name="orientation"
                  value={ORIENTATION.HORIZONTAL}
                  label={intl.formatMessage({ id: 'message.horizontalDisplay' })}
                  tabIndex="0"
                />

                <Form.Check
                  type="radio"
                  onChange={handleChange}
                  checked={data.orientation === ORIENTATION.VERTICAL}
                  className="mr-5"
                  name="orientation"
                  value={ORIENTATION.VERTICAL}
                  label={intl.formatMessage({ id: 'message.verticalDisplay' })}
                  tabIndex="0"
                />
              </div>
            </>
          )}
          {data.expectedLines !== undefined && (
            <>
              <div className="mb-2">
                <span className="caption-metadata">
                  <FormattedMessage id="formatTrueFalse" />
                </span>
                <span className="normal-text">
                  <FormattedMessage id="visibleInPrintViewTrueFalse" />
                </span>
              </div>
              <div className="d-flex gap-2 pl-4 align-items-center">
                <label htmlFor="option1">
                  <FormattedMessage id="EssaySpaceLabel" defaultMessage="Essay Space:" />
                </label>
                {ESSAY_QUESTION_SIZE_DATA.map(option => (
                  <React.Fragment key={option.id}>
                    <input
                      type="radio"
                      id={option.id}
                      name="expectedLines"
                      value={option.value}
                      checked={data.expectedLines === option.value}
                      onChange={handleChange}
                    />
                    <label htmlFor={option.id} tabIndex="0">
                      <FormattedMessage id={option.id} defaultMessage={option.defaultMessage} />
                    </label>
                  </React.Fragment>
                ))}
              </div>
            </>
          )}
          <div className="mt-3 metadata-fields-container">
            <FormattedMessage id="message.metadataTitle" />
            <Row className="m-2">
              <Col xs={12}>
                {userMetadata.includes(METADATA_OPTIONS.QUESTION_ID) && (
                  <Form.Group controlId="formGridID" className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="questionIdLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="Question ID"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.QUESTION_ID}
                      value={data.questionId}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.DIFFICULTY) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="difficultyLabel" />
                    </Form.Label>
                    <Form.Select
                      aria-label="difficultyLabel "
                      className=""
                      size="md"
                      name={METADATA_OPTIONS.DIFFICULTY}
                      value={data.difficulty}
                      onChange={handleDropdownChange}
                    >
                      <option value="" className="font-arial">
                        <FormattedMessage id="chooseLevel" />
                      </option>
                      <option value="Easy" className="font-arial">
                        <FormattedMessage id="difficultyEasy" />
                      </option>
                      <option value="Moderate" className="font-arial">
                        <FormattedMessage id="difficultyMedium" />
                      </option>
                      <option value="Difficult" className="font-arial">
                        <FormattedMessage id="difficultyHard" />
                      </option>
                    </Form.Select>
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.PAGE_REFERENCE) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="pageReferenceLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="pageReferenceLabel"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.PAGE_REFERENCE}
                      value={data.pageReference}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.TOPIC) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="topicLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="topicLabel"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.TOPIC}
                      value={data.topic}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.SKILL) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="skillLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="skillLabel"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.SKILL}
                      value={data.skill}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
                {userMetadata.includes(METADATA_OPTIONS.OBJECTIVE) && (
                  <Form.Group className="mt-2 d-flex align-items-center">
                    <Form.Label>
                      <FormattedMessage id="objectiveLabel" />
                    </Form.Label>
                    <Form.Control
                      aria-label="objectiveLabel"
                      type="text"
                      placeholder=""
                      className=""
                      name={METADATA_OPTIONS.OBJECTIVE}
                      value={data.objective}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default FormatAndAddExtendedMetadataSection;
