import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './password.css';

const Password = ({ name, value, placeholder, onChange, ...otherProps }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <div className="password-input-field">
      <Form.Control
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        {...otherProps}
      />
      <div onClick={togglePasswordVisibility} className="pwd-toggle-icon">
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} title={showPassword ? 'Hide' : 'Show'} />
      </div>
    </div>
  );
};

export default Password;
