import React, { useEffect } from 'react';
import ManageBooksTab from 'workspace/components/ManageBooksTab/ManageBooksTab';

const ManageBooksPage = () => {
  useEffect(() => {
    document.title = 'Manage Books';
  }, []);

  return (
    <>
      <ManageBooksTab />
    </>
  );
};

export default ManageBooksPage;
