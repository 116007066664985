import axios from './axios';
import { extractErrorDetails } from 'common/utils';

/**
 * Logs in a user with the provided username and password.
 *
 * @param {string} username - The username to log in with.
 * @param {string} password - The password to log in with.
 *
 * @returns {Promise<object>} - A promise that resolves with the login response data.
 * @throws {Error} - If the login request fails, an error with a extracted error message is thrown.
 *
 */
export async function login(username, password) {
  try {
    const response = await axios.post('users/login', {
      username,
      password,
    });

    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}
