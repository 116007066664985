export const DEFAULT_EXTENDED_METADATA = {
  questionId: '',
  pageReference: '',
  topic: '',
  skill: '',
  objective: '',
  difficulty: '',
};

export const FILTER_KEYS_MAP = {
  questionId: 'QnId',
  pageReference: 'PRef',
  topic: 'Topk',
  skill: 'Skil',
  objective: 'Objt',
  difficulty: 'Diff',
};

export const QUESTION_TYPES = {
  Essay: 'Essay',
  MultipleChoice: 'MultipleChoice',
  FillInBlanks: 'FillInBlanks',
  Matching: 'Matching',
  MultipleResponse: 'MultipleResponse',
  TrueFalse: 'TrueFalse',
};

export const ORIENTATION = {
  VERTICAL: 'Vertical',
  HORIZONTAL: 'Horizontal',
};

export const ESSAY_ANSWER_LINES = {
  0: 0,
  10: 7,
  20: 14,
  40: 28,
};
