import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tree } from '@minoru/react-dnd-treeview';
import { useAppContext } from '../../../context/AppContext';
import { deleteTestFolder, getUserTestFolders, deleteTest } from '../../../services/testfolder.service';
import { getFolderTests } from '../../../services/testcreate.service';
import { toastify } from '../../../../common/components/Toastify';
import NoDragWrapper from '../../../../common/components/NoDragWrapper';
import Placeholder from '../../../../common/components/Placeholder';
import './TestFolderTreeView.css';

function TestFolderTreeView({
  onFolderSelect,
  onNodeUpdate,
  onNodeUpdateTest,
  folders,
  rootFolderGuid,
  selectedFolderGuid,
}) {
  const intl = useIntl();
  const { tests, selectedTest, dispatchEvent, testTabsDispatch, setClickedNodes, clickedNodes } = useAppContext();
  const [treeData, setTreeData] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [showFolderDeleteConfirmModal, setShowFolderDeleteConfirmModal] = useState(false);
  const [selectedFolderToDelete, setSelectedFolderToDelete] = useState(null);
  const [selectedTestToDelete, setSelectedTestToDelete] = useState(null);
  const [showTestDeleteModal, setShowTestDeleteModal] = useState(false);

  useEffect(() => {
    dispatchEvent('SHOW_LOADER');
    try {
      if (folders && folders.length > 0) {
        const updatedTreeData = folders.map(folder => ({
          id: folder.guid,
          parent: 0,
          droppable: folder.parentId ? true : false,
          text: folder.title,
          data: {
            guid: folder.guid,
            sequence: folder.sequence,
          },
        }));
        setTreeData(updatedTreeData);
      }
    } catch (error) {
      console.error('Error fetching folders and tests', error);
      toastify.showErrorToast(intl.formatMessage({ id: 'error.Error.whileFetchingFoldersAndTests' }));
    } finally {
      dispatchEvent('HIDE_LOADER');
    }
  }, [folders]);

  const fetchChildFolders = async parentNode => {
    try {
      if (!parentNode.children && parentNode.data.guid !== selectedFolderGuid && parentNode.droppable === true) {
        const childFolders = await getUserTestFolders(parentNode.data.guid);
        const childTests = await getFolderTests(parentNode.data.guid);

        const childNodes = [
          ...childFolders.map(childFolder => ({
            id: childFolder.guid,
            parent: parentNode.id,
            droppable: true,
            text: childFolder.title,
            data: {
              guid: childFolder.guid,
              sequence: childFolder.sequence,
            },
          })),
          ...childTests.map(childTest => ({
            id: childTest.guid,
            parent: parentNode.id,
            droppable: false,
            text: childTest.title,
            data: {
              guid: childTest.guid,
            },
          })),
        ];

        const updatedTreeData = [...treeData];
        const nodeIndex = updatedTreeData.findIndex(n => n.id === parentNode.id);

        const existingChildNodes = updatedTreeData.slice(0).filter(node => node.parent === parentNode.id);

        if (existingChildNodes.length === 0) {
          updatedTreeData.splice(nodeIndex + 1, 0, ...childNodes);
        } else {
          //isInUpdatedTreeData holds funtions with one argument newNode
          //to get rid of the duplicates from treeData and childNodes
          const isInUpdatedTreeData = newNode => updatedTreeData.some(existingNode => existingNode.id === newNode.id);
          const filteredChildNodes = childNodes.filter(newNode => !isInUpdatedTreeData(newNode));

          if (filteredChildNodes.length > 0) {
            // Add the filtered child nodes (non-duplicates)
            updatedTreeData.splice(nodeIndex + 1, 0, ...filteredChildNodes);
          }
        }
        setTreeData(updatedTreeData);
      }
    } catch (error) {
      console.error('Error fetching child test folders', error);
      toastify.showErrorToast(intl.formatMessage({ id: 'error.fetchingChildFolders' }));
    }
  };

  const handleDrop = async (newTree, e) => {
    const { dragSource, dropTarget } = e;
    if (dragSource?.data.guid === dropTarget?.data.guid) {
      return;
    }
    if (dragSource?.id === dropTarget?.parent) {
      return;
    }
    if (dragSource?.droppable === true && dropTarget?.droppable === false) {
      return;
    }
    if (
      dragSource?.droppable === false &&
      dropTarget?.droppable === false &&
      dragSource?.data?.sequence === undefined &&
      dropTarget?.data?.sequence === undefined
    ) {
      return;
    }
    if (dragSource?.droppable === false && dragSource?.parent === 0 && !dropTarget) {
      return;
    }
    if (dragSource?.droppable === true && dragSource?.data?.sequence !== undefined) {
      let parentId;

      if (dropTarget && dropTarget.data) {
        parentId = dropTarget.data.guid;
      } else {
        parentId = rootFolderGuid;
      }

      let newSequence;

      if (!dropTarget) {
        const indexOfDraggedNode = newTree.findIndex(node => node.id === dragSource.data.guid);
        const sequenceTop = newTree[indexOfDraggedNode - 1]?.data.sequence || newTree.length + 1;
        const sequenceBottom = newTree[indexOfDraggedNode + 1]?.data.sequence || 0;
        newSequence = (sequenceTop + sequenceBottom) / 2;
        const oldTreeData = treeData.map(node => node.data.guid);
        const newTreeData = newTree.map(node => node.data.guid);

        if (JSON.stringify(oldTreeData) === JSON.stringify(newTreeData)) {
          return;
        }
      } else {
        newSequence = dragSource.data.sequence;
      }

      const nodeToBeUpdated = {
        guid: dragSource.data.guid,
        parentId: parentId,
        sequence: newSequence,
        title: dragSource.text,
      };

      try {
        const childFolders = await getUserTestFolders(parentId);
        const childNodes = childFolders.map((childFolder, index) => ({
          id: `${parentId}.${index + 1}`,
          parent: parentId,
          droppable: true,
          text: childFolder.title,
          data: {
            guid: childFolder.guid,
            sequence: childFolder.sequence,
          },
        }));
        const parentIndex = newTree.findIndex(node => node.id === parentId);
        const isChildNode = parentId.toString().includes('.');
        const updatedParentIndex = isChildNode ? parentIndex - 1 : parentIndex;
        const updatedTreeData = [...newTree];
        updatedTreeData.splice(updatedParentIndex + 1, 0, ...childNodes);
        if (childFolders.length != 0) {
          updatedTreeData.splice(updatedParentIndex + 1, childFolders.length);
        }
        setTreeData(updatedTreeData);
        localStorage.setItem('savedFolders', JSON.stringify(updatedTreeData));
      } catch (error) {
        console.error('Failed to rearrange test', error);
        toastify.showErrorToast(intl.formatMessage({ id: 'error.failed.To.rearrangetest' }));
      }
      onNodeUpdate(nodeToBeUpdated);
    } else {
      let targetId;

      if (dropTarget === undefined) {
        targetId = rootFolderGuid;
      } else {
        targetId = dropTarget.id;
      }
      onNodeUpdateTest(dragSource.parent, targetId, dragSource.data.guid);
      const updatedTreeData = [...newTree];
      setTreeData(updatedTreeData);
    }
  };

  const handleEditFolder = (folderTitle, parentId) => {
    if (selectedFolder === folderTitle) {
      setSelectedFolder(null);
      if (onFolderSelect) {
        onFolderSelect('');
      }
    } else {
      if (onFolderSelect) {
        onFolderSelect(folderTitle, parentId);
      }
      setSelectedFolder(folderTitle);
    }
  };

  const handleTestEditClick = node => {
    node.ismigrated = false;
    dispatchEvent('HANDLE_VIEW_OR_EDIT_TEST_CLICK', node);
  };

  const handleDeleteFolder = folderTitle => {
    setSelectedFolderToDelete(folderTitle);
    setShowFolderDeleteConfirmModal(true);
  };

  const handleFolderDeleteModalCancel = () => {
    setShowFolderDeleteConfirmModal(false);
    setSelectedFolderToDelete(null);
  };

  const handleFolderDeleteModalConfirm = async () => {
    try {
      const folderToDelete = treeData.find(folder => folder.text === selectedFolderToDelete);
      if (!folderToDelete) {
        console.error('Folder not found:', selectedFolderToDelete);
        return;
      }
      const folderIdToDelete = folderToDelete.id;
      let childTestGuids = [];

      // Recursive function to retrieve tests from a folder and its subfolders
      const retrieveAllTests = async folderId => {
        const childTests = await getFolderTests(folderId);
        const childFolders = await getUserTestFolders(folderId);

        // Store the GUIDs of the child tests
        childTestGuids = childTestGuids.concat(childTests.map(test => test.guid));

        // Recursively retrieve tests from subfolders
        for (const subFolder of childFolders) {
          await retrieveAllTests(subFolder.guid);
        }
      };

      await retrieveAllTests(folderIdToDelete);

      await deleteTestFolder(folderIdToDelete);
      const updatedTreeData = treeData.filter(node => node.data.guid !== folderIdToDelete);
      setTreeData(updatedTreeData);

      // Filter the tests array to exclude the deleted tests
      const updatedTests = tests.filter(
        test => !childTestGuids.includes(test.metadata.guid) && !childTestGuids.includes(test.testId)
      );

      testTabsDispatch({ type: 'UPDATE_TESTS_AFTER_FOLDER_DELETE', payload: updatedTests });

      // Handle UI updates for deleted tests
      childTestGuids.forEach(childTestGuid => {
        const index = tests.findIndex(test => test.testId === childTestGuid || test.metadata.guid === childTestGuid);
        if (index >= 0) {
          const testTobeRemoved = tests[index];
          testTabsDispatch({ type: 'REMOVE_TEST_FROM_TABS', payload: testTobeRemoved });
        }
      });

      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.delete' }));
    } catch (error) {
      console.error('Error deleting folder:', error);
      toastify.showErrorToast(intl.formatMessage({ id: 'error.failedtodeletefolder' }));
    } finally {
      setShowFolderDeleteConfirmModal(false);
      setSelectedFolderToDelete(null);
    }
  };

  const deleteTestInsideFolder = async (folderId, testId) => {
    try {
      await deleteTest(folderId, testId);
      console.log('Test deleted:', testId);
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.test.deletesuccessfully' }));
    } catch (error) {
      console.error('Error deleting test:', error);
      toastify.showErrorToast(intl.formatMessage({ id: 'error.failedtodeletetest' }));
    }
  };

  const handleDeleteTest = (folderId, testId) => {
    if (!folderId) {
      setSelectedTestToDelete({ folderId: rootFolderGuid, testId });
      setShowTestDeleteModal(true);
    } else {
      setSelectedTestToDelete({ folderId, testId });
      setShowTestDeleteModal(true);
    }
  };

  /**
   * Handles the confirmation of deleting a test.
   *
   * Deletes the test inside a folder, updates the tree data by removing the deleted test,
   * and resets the selected test to delete.
   */
  const handleConfirmDeleteTest = async () => {
    try {
      // Extracts the folder ID and test ID from the selected test to delete.
      const { folderId, testId } = selectedTestToDelete;
      // makes an API call to delete the test inside the folder.
      await deleteTestInsideFolder(folderId, testId);

      // Filters out the deleted test from the tree data.
      const updatedTreeData = treeData.filter(node => {
        // Checks if the node is the test to delete.
        const isTestToDelete =
          (node.parent === folderId && node.data && node.data.guid === testId) ||
          (folderId === rootFolderGuid && node.data && node.data.guid === testId);

        return !isTestToDelete;
      });

      // Updates the tree data with the filtered data.
      setTreeData(updatedTreeData);
      const index = tests.findIndex(test => test.testId === testId || test.metadata.guid == testId);
      const tabFound = index >= 0;

      if (tabFound) {
        const testTobeRemoved = tests[index];

        testTabsDispatch({ type: 'REMOVE_TEST_FROM_TABS', payload: testTobeRemoved });
      }
    } catch (error) {
      console.error('Error deleting test:', error);
      toastify.showErrorToast(intl.formatMessage({ id: 'error.failedtodeletetest' }));
    }

    // Hides the delete confirmation modal and resets the selected test to delete.
    setShowTestDeleteModal(false);
    setSelectedTestToDelete(null);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleCaretClick = (node, isOpen, onToggle) => {
    onToggle();

    if (isOpen) {
      setClickedNodes(prevClickedNodes => {
        if (prevClickedNodes.includes(node.id)) {
          return prevClickedNodes.filter(item => item !== node.id);
        }
        return prevClickedNodes;
      });
    } else {
      if (!node.children || node.children.length === 0) {
        fetchChildFolders(node);
      }

      setClickedNodes(prevClickedNodes => {
        if (prevClickedNodes.includes(node.id)) {
          return prevClickedNodes;
        } else {
          return [...prevClickedNodes, node.id];
        }
      });
    }
  };

  return (
    <div className={`treeview ${isDragging ? 'grabbing' : ''}`} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
      <Tree
        tree={treeData}
        sort={false}
        rootId={0}
        canDrag={node => !node.noDrag}
        canDrop={() => true}
        dropTargetOffset={5}
        onDrop={handleDrop}
        dragPreviewRender={monitorProps => <div className="custom-drag-preview">{monitorProps.item.text}</div>}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
        render={(node, { isOpen, onToggle }) => (
          <div node={node} className={`test-folder-tree-node ${clickedNodes.includes(node.id) ? 'clicked' : ''}`}>
            {node.droppable && (
              <NoDragWrapper node={node}>
                <span className="caret-container" onClick={() => handleCaretClick(node, isOpen, onToggle)}>
                  {clickedNodes.includes(node.id) ? (
                    <i className="fa fa-caret-down"></i>
                  ) : (
                    <i className="fa fa-caret-right"></i>
                  )}
                </span>
              </NoDragWrapper>
            )}
            <div className="tree-node-text" tabIndex="0">
              {node.text}
            </div>

            <NoDragWrapper node={node}>
              <div className="tree-node-action-buttons-container">
                {node.droppable && (
                  <button
                    className={`action-button ${selectedFolder === node.text ? 'selected' : ''}`}
                    onClick={() => handleEditFolder(node.text, node.parent)}
                    title={intl.formatMessage({ id: 'message.edit', defaultMessage: 'Edit' })}
                    aria-label="Edit"
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </button>
                )}
                {!node.droppable && (
                  <button
                    className={`action-button ${selectedTest?.testId === node.data.guid ? 'highlight' : ''}`}
                    onClick={() => handleTestEditClick(node)}
                    title={intl.formatMessage({ id: 'message.edit', defaultMessage: 'Edit' })}
                    aria-label="Edit"
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </button>
                )}
                <button
                  className="action-button"
                  onClick={() =>
                    !node.droppable ? handleDeleteTest(node.parent, node.data.guid) : handleDeleteFolder(node.text)
                  }
                  title={intl.formatMessage({ id: 'message.delete', defaultMessage: 'Delete' })}
                  aria-label="Delete"
                >
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            </NoDragWrapper>
          </div>
        )}
      />
      {showFolderDeleteConfirmModal && (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header" id="delete-modal-header">
                <h5 className="modal-title">
                  <FormattedMessage id="deleteFolderTreeView" />
                </h5>
              </div>
              <div className="modal-body">
                <i className="fa-solid fa-circle-question"></i>
                &nbsp;
                <FormattedMessage id="modal.delete.folder.confirmation.message" />
              </div>
              <div className="modal-footer" id="delete-modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleFolderDeleteModalCancel}>
                  <FormattedMessage id="message.cancel" />
                </button>
                <button type="button" className="btn btn-primary" onClick={handleFolderDeleteModalConfirm}>
                  <FormattedMessage id="message.delete" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showTestDeleteModal && (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header" id="delete-modal-header">
                <h5 className="modal-title">
                  <FormattedMessage id="deleteTestTreeViewTitle" />
                </h5>
              </div>
              <div className="modal-body">
                <i className="fa-solid fa-circle-question"></i>
                &nbsp; <FormattedMessage id="deleteTestQuestionTreeView" />
              </div>
              <div className="modal-footer" id="delete-modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowTestDeleteModal(false)}>
                  <FormattedMessage id="message.cancel" />
                </button>
                <button type="button" className="btn btn-primary" onClick={handleConfirmDeleteTest}>
                  <FormattedMessage id="message.delete" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TestFolderTreeView;
