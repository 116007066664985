import React, { useState } from 'react';
import CreateUser from './CreateUser';
import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import { getUsers } from 'workspace/api/users.service';
import UsersList from 'workspace/components/ManageUsersTab/UsersList';
import { useAuth } from 'workspace/providers/auth/AuthContext';
import './manage-users-tab.css';

const ManageUsersTab = () => {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState('');
  const { showLoader, hideLoader } = useLoader();
  const { userData } = useAuth();
  const [loading, setLoading] = useState(true);

  const fetchUsers = async () => {
    try {
      showLoader();
      const usersData = await getUsers(userData.role, userData.userId);
      setUsers(usersData);
      setLoading(true);
    } catch (err) {
      if (err.status === 404) {
        setUsers([]);
        return;
      } else {
        toastify.showErrorToast('Unable to fetch user data at this time. Please try again later.');
      }
    } finally {
      hideLoader();
      setLoading(false);
    }
  };

  const handleShowModalPopup = () => {
    setShow(true);
  };

  const handleEditUser = user => {
    setEditUser(user);
  };

  const handleClose = () => {
    setShow(false);
    setEditUser('');
  };

  return (
    <div className="user-functions">
      <UsersList
        users={users}
        handleShowModalPopup={handleShowModalPopup}
        handleEditUser={handleEditUser}
        fetchUsers={fetchUsers}
        loading={loading}
      />
      {show && <CreateUser handleClose={handleClose} editUser={editUser} reload={fetchUsers} />}
    </div>
  );
};

export default ManageUsersTab;
