import { FormattedMessage } from 'react-intl';

const NoPage = () => {
  return (
    <>
      <FormattedMessage id="notFoundErrorMessage" />
    </>
  );
};

export default NoPage;
