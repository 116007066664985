import { useRef, useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Editor, EditorProvider, Toolbar } from 'lib/rte';
import EditViewActionButtons from '../EditViewActionButtons';
import FormatAndAddExtendedMetadataSection from '../FormatAndAddExtendedMetadataSection';
import { toastify } from 'common/components/Toastify';
import useFormatAndExtendedMetadata from '../hooks/useFormatAndExtendedMetadata';
import { getFIBBlanks } from './fib-utils';

const MAX_BLANKS_COUNT = process.env.REACT_APP_ANSWER_OPTIONS_COUNT;

const FillInBlanksEditView = ({ questionNode, questionNodeIndex, userMetadata, onSubmit, onDelete }) => {
  const { Caption, Blanks } = questionNode.qtiModel;
  const intl = useIntl();
  const fibEditorRef = useRef();

  const [questionData, setQuestionData] = useState(() => {
    const blanks = getFIBBlanks(Caption, Blanks);

    return {
      Caption,
      blanks,
    };
  });

  const [formatAndExtendedMetadata, setFormatAndExtendedMetadata] = useFormatAndExtendedMetadata(questionNode);

  const handleFIBAnswerChange = (item, value) => {
    setQuestionData(prevFormData => ({
      ...prevFormData,
      blanks: prevFormData.blanks.map(blank => {
        if (blank.key === item.key) {
          blank.answer = value;
        }

        return blank;
      }),
    }));
  };

  const handleFIBBlankSpace = (item, value) => {
    setQuestionData(prevFormData => ({
      ...prevFormData,
      blanks: prevFormData.blanks.map(blank => {
        if (blank.key === item.key) {
          blank.blankSpace = value;
        }

        return blank;
      }),
    }));
  };

  const handleAddBlank = event => {
    if (questionData.blanks.length < MAX_BLANKS_COUNT) {
      fibEditorRef.current.insertBlank();
    } else {
      const msg = intl.formatMessage({ id: 'warning.cannotAddMoreBlanks' }, { count: MAX_BLANKS_COUNT });
      toastify.showWarningToast(msg);
    }
    event.target.blur();
  };

  const handleDeleteBlank = key => {
    fibEditorRef.current.deleteBlank(key);
  };

  const handleEditorChange = text => {
    const blanks = getFIBBlanks(text, questionData.blanks);

    setQuestionData(data => ({ ...data, Caption: text, blanks }));
  };

  return (
    <div className="m-2 border rounded p-3 prb-1 bg-light">
      <EditorProvider>
        <Toolbar />
        <Form className="editmode paddingright10">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="mb-1">
              <b>{intl.formatMessage({ id: 'label.fillInBlanks.title' })}</b>
            </Form.Label>
            <Editor
              ref={fibEditorRef}
              placeholder={intl.formatMessage({ id: 'placeholder.fillInBlanks.caption' })}
              allowBlanks={true}
              value={questionData.Caption}
              onChange={handleEditorChange}
              className="rounded form-control fib-content-area"
            />
            <div className="add-blank-container">
              <Button
                id="dropdown-item-button"
                title={intl.formatMessage({ id: 'addBlankButton', defaultMessage: 'Add Blank' })}
                className="add-blank-button"
                onClick={handleAddBlank}
              >
                <FormattedMessage id="addBlankButton" defaultMessage="Add Blank" />
              </Button>
            </div>
            {questionData.blanks?.length > 0 && (
              <div className="fib-answers-area">
                <b>
                  <FormattedMessage id="correctAnswerFillInBlanks" defaultMessage="Correct Answer" />
                </b>

                <div id="fbAnswerContainer" className="mb-1 mt-3 d-flex flex-wrap">
                  {questionData.blanks.map((item, index) => {
                    return (
                      <div key={index} className="mc-flex-row mb-2">
                        <div className="mc-col fb-col-1">{String.fromCharCode(65 + index) + '.'}</div>
                        <div className="mc-col fb-col-2">
                          <Form.Control
                            type="text"
                            name={item.name}
                            value={item.answer}
                            placeholder={intl.formatMessage(
                              { id: 'placeholder.fillInBlanks.blank' },
                              { blank: String.fromCharCode(65 + index) }
                            )}
                            onChange={e => handleFIBAnswerChange(item, e.target.value)}
                          />
                        </div>
                        <div className="d-flex m-1 gap-1">
                          <FormGroup>
                            <Form.Select
                              id="blankSize"
                              value={item.blankSpace}
                              onChange={e => handleFIBBlankSpace(item, Number(e.target.value))}
                            >
                              <option value="" disabled className="blanksize">
                                <FormattedMessage id="blankSizeLabel" defaultMessage="Blank Size" />
                              </option>
                              <option id="option1" value="20" name={item.name} className="blanksize">
                                <FormattedMessage id="smallOption" defaultMessage="Small" />
                              </option>
                              <option id="option2" value="40" name={item.name} className="blanksize">
                                <FormattedMessage id="mediumOption" defaultMessage="Medium" />
                              </option>
                              <option value="80" id="option3" name={item.name} className="blanksize">
                                <FormattedMessage id="largeOption" defaultMessage="Large" />
                              </option>
                              <option value="110" id="option4" name={item.name} className="blanksize">
                                <FormattedMessage id="extraLargeOption" defaultMessage="Extra Large" />
                              </option>
                            </Form.Select>
                          </FormGroup>
                          <Button
                            className="opt-close-button highlight-icon-button "
                            title={intl.formatMessage({
                              id: 'message.remove',
                              defaultMessage: 'Delete Option',
                            })}
                            variant="link"
                            onClick={() => handleDeleteBlank(item.key)}
                          >
                            <i className="bi bi-x-lg"></i>
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Form.Group>
          <FormatAndAddExtendedMetadataSection
            data={formatAndExtendedMetadata}
            setData={setFormatAndExtendedMetadata}
            userMetadata={userMetadata}
          />
        </Form>

        <EditViewActionButtons
          index={questionNodeIndex}
          questionNode={questionNode}
          questionData={questionData}
          formatAndExtendedMetadata={formatAndExtendedMetadata}
          onSubmit={onSubmit}
          onDelete={onDelete}
        />
      </EditorProvider>
    </div>
  );
};

export default FillInBlanksEditView;
