import { AxiosError } from 'axios';
import httpInterceptor from '../../common/httpHelper/httpHelper';

const url = process.env.REACT_APP_API_URL;

export const saveMyQuestions = questionEnvelops => {
  return httpInterceptor
    .post(`${url}/my/questions`, questionEnvelops)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const saveMyTest = (test, folderId) => {
  if (!folderId) folderId = 'null';
  return httpInterceptor
    .post(`${url}/my/folders/${folderId}/tests`, test)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const getPrintsettings = async () => {
  try {
    const response = await httpInterceptor.get(`${url}/settings/printsettings`);
    return response.data;
  } catch (error) {
    throw error instanceof AxiosError ? error.message : error;
  }
};

export const savePrintsettings = data => {
  try {
    const response = httpInterceptor.post(`${url}/settings/printsettings`, data);
    return response?.data;
  } catch (error) {
    throw error instanceof AxiosError ? error.message : error;
  }
};

export const getFolderTests = folderId => {
  return httpInterceptor
    .get(`${url}/my/folders/${folderId}/tests`)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const getRootTests = () => {
  return httpInterceptor
    .get(`${url}/my/testroot`)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const getTestQuestions = testId => {
  return httpInterceptor
    .get(`${url}/test/${testId}/questions`)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const getPublisherTestsByBookId = bookId => {
  return httpInterceptor
    .get(`${url}/books/${bookId}/tests`)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

/**
 * Exports a test in the specified format with the given options.
 *
 * @param {string} testId - The ID of the test to export.
 * @param {string} format - The format to export the test in.
 * @param {Object} options - An object containing additional options for the export.
 *
 * @returns {Promise<Blob>} A promise that resolves with the exported test data as a blob.
 */
export const exportTest = async (testId, format, options) => {
  let data = '';
  for (const key in options) {
    data += `${key}=${options[key]}$`;
  }
  data = data.slice(0, -1);

  const base64Data = btoa(data);
  const downloadURL = `${url}/tests/${testId}/download/${format}?data=${base64Data}`;
  try {
    const response = await httpInterceptor.get(downloadURL, { responseType: 'blob' });

    if (response.status !== 200) {
      throw response instanceof AxiosError ? response.message : response;
    }

    return response.data;
  } catch (error) {
    throw error instanceof AxiosError ? error.message : error;
  }
};
