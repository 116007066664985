import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button, Card } from 'react-bootstrap';
import TBHeader from '../layouts/TBHeader';
import './WelcomeScreen.css';

const WelcomeScreen = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/discipline');
  };

  useEffect(() => {
    document.title = 'Welcome Screen';
  }, []);

  return (
    <>
      <TBHeader />

      <div className="welcome-screen-container">
        <Card className="card-container">
          <Card.Body>
            <Card.Title className="big-title">
              <FormattedMessage id="cardTitle" />
            </Card.Title>
            <Card.Text className="small-text">
              <FormattedMessage id="cardDescription" />
            </Card.Text>
            <hr className="horizontal-rule" />
            <Card.Text className="center-text">
              <FormattedMessage id="stepSetupText" />
            </Card.Text>
            <div className="three-step-setup-container">
              <div className="step">
                <span className="number">1</span>
                <Card.Text className="step-setup-text">
                  <FormattedMessage id="step1Text" />
                </Card.Text>
              </div>
              <div className="step">
                <span className="number">2</span>
                <Card.Text className="step-setup-text">
                  <FormattedMessage id="step2Text" />
                </Card.Text>
              </div>
              <div className="step">
                <span className="number">3</span>
                <Card.Text className="step-setup-text">
                  <FormattedMessage id="step3Text" />
                </Card.Text>
              </div>
            </div>
            <Button
              variant="primary"
              className="start-button"
              aria-label="start button to activate press enter"
              onClick={handleStart}
            >
              <FormattedMessage id="startButtonText" />
            </Button>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default WelcomeScreen;
