import React from 'react';

const Placeholder = ({ node, depth }) => {
  return (
    <div
      style={{
        position: 'relative',
        top: 0,
        right: 0,
        height: 1,
        left: 0,
        transform: 'translateY(-50%)',
        backgroundColor: '#006a8d',
        zIndex: 100,
      }}
    />
  );
};

export default Placeholder;
