import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import { useDrop } from 'react-dnd';
import { useAppContext } from '../context/AppContext';
import QuestionsBanksTips from './testTabs/QuestionsBanksTips/QuestionsBanksTips';
import TreeViewTestCreate from './TreeViewTestCreate';
import { sanitizeTitle } from '../utils/test-utils';
import { deepClone } from 'common/utils';
import { toastify } from 'common/components/Toastify';
import { transformQuestionTemplateToQuestion } from 'common/utils/questions-utils';
import './TestCreate.css';
import '../../styles/_tables.css';

const MAX_QUESTIONS_COUNT = process.env.REACT_APP_TEST_QUESTIONS_COUNT;

const TestCreate = () => {
  const { selectedTest, dispatchEvent, testTabsDispatch } = useAppContext();
  const [tabTitle, setTabTitle] = useState(selectedTest?.title || '');
  const [isEditing, setIsEditing] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    setTabTitle(selectedTest?.title || '');
  }, [selectedTest]);

  const handleTitleChange = event => {
    const title = sanitizeTitle(event.target.value);

    setTabTitle(title);
    setIsEditing(true);

    // Update the title in the selectedTest object
    if (selectedTest && selectedTest.id) {
      // Dispatch an action to update the selectedTest object in the context
      testTabsDispatch({ type: 'UPDATE_SELECTED_TEST_TITLE', payload: { title } });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (tabTitle.trim().length === 0) {
      return;
    } else {
      testTabsDispatch({ type: 'UPDATE_SELECTED_TEST_TITLE', payload: { title: tabTitle } });
      setIsEditing(false);
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ['QUESTION_TEMPLATE', 'TREE_NODE', 'SAVED_QUESTION'],
    drop: item => {
      if (selectedTest.questions.length >= MAX_QUESTIONS_COUNT) {
        const msg = intl.formatMessage({ id: 'warning.morequestions' }, { count: MAX_QUESTIONS_COUNT });
        toastify.showWarningToast(msg);
        return;
      }

      let copyItem = deepClone(item);
      let newQuestion = copyItem.question;

      if (copyItem.question.isQuestionTemplate) {
        delete copyItem.question.isQuestionTemplate;
        newQuestion = transformQuestionTemplateToQuestion(copyItem.question);
      }

      dispatchEvent('ADD_NEW_QUESTION_TO_TEST', newQuestion);

      setIsEditing(true);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <>
      <div className="test-container pb-0">
        <div className="d-flex align-items-center test-name-edit-container p1">
          <div className="gap-4 d-flex align-items-center w-100">
            <div className="ml-2 p-500">
              <FormattedMessage id="testName" />
            </div>
            <Form className="flex-grow-1" onSubmit={handleSubmit}>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter Test title"
                value={tabTitle}
                onChange={handleTitleChange}
                className="rounded testNameInputBox"
                required={true}
              />
            </Form>
          </div>
        </div>
      </div>
      <div className="test-questions-container">
        {selectedTest?.questions?.length ? (
          <>
            <div className="pt-0">
              <TreeViewTestCreate data={selectedTest.questions} />
            </div>
            <div ref={drop} className={`${canDrop && isOver && !isEditing ? 'drop-active' : ''}`}>
              <div className="drag-container align-items-center d-flex justify-content-center">
                <FormattedMessage id="message.dragQuestionsHere" />
              </div>
            </div>
          </>
        ) : (
          <div ref={drop}>
            <QuestionsBanksTips />
          </div>
        )}
      </div>
    </>
  );
};

export default TestCreate;
