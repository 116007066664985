import { Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ROLE_ACCESS } from 'workspace/constants';
import { useAuth } from 'workspace/providers/auth/AuthContext';

const WSTabs = () => {
  const { pathname } = useLocation();
  const { userData } = useAuth();

  return (
    <div className="resource-tab-links">
      <Nav className="nav-resource-tab-links" variant="tabs" activeKey={'#' + pathname}>
        {ROLE_ACCESS[userData.role].canManageUsers && (
          <Nav.Item>
            <Nav.Link href="#/ws/manageusers">
              <FormattedMessage id="ws.manageuser" />
            </Nav.Link>
          </Nav.Item>
        )}
        {ROLE_ACCESS[userData.role].canManageBooks && (
          <Nav.Item>
            <Nav.Link href="#/ws/managebooks">
              <FormattedMessage id="ws.managebook" />
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
    </div>
  );
};

export default WSTabs;
