import deepEqual from 'deep-equal';
import Test from '../entities/Test.Entity';
import { getTestQuestions } from '../services/testcreate.service';
import { constructQuestionObject, isAllQuestionsAreInViewMode } from '../../common/utils/questions-utils';

const MAX_ALLOWED_TEST_TABS = process.env.REACT_APP_MAX_ALLOWED_TEST_TABS_COUNT;

/**
 * Formats a test title by removing disallowed characters and ensuring it is not longer than 255 characters.
 * Also capitalizes the first letter of the title.
 *
 * @param {string} title - The title to be formatted.
 * @return {string} The formatted title.
 */
export const sanitizeTitle = title => {
  // Remove disallowed characters and ensure title is not longer than 255 characters
  const disallowedCharacters = /[<>:"/\\|?]/;
  title = title.replace(disallowedCharacters, '').slice(0, 255);

  // Capitalize the first letter of the title
  title = title.charAt(0).toUpperCase() + title.slice(1);

  return title;
};

/**
 * Constructs a Test object from a given node.
 *
 * @param {Object} node - The node object to construct the test from.
 * @returns {Test} - The constructed Test object.
 */
export const constructTest = async node => {
  const testId = node.guid || node.id;
  const title = node.title || node.text;

  // Create a new Test object
  const test = new Test();

  // Set the test's title and tab title
  test.title = title;
  test.tabTitle = title;

  // Set the test's folder GUID, or null if the node has no parent
  test.folderGuid = node.parent === 0 ? null : node.parent;

  // Set the test's ID and migration status
  test.testId = testId;
  test.ismigrated = node.ismigrated;

  // Initialize the test's metadata object
  test.metadata = {};

  // Get the questions associated with the test
  const questions = await getTestQuestions(testId);

  // Convert each question DTO to a Question object and add to the test's questions array
  test.questions = questions.map(question => constructQuestionObject(question));

  // Return the constructed Test object
  return test;
};

/**
 * Checks if a test is ready to be saved.
 *
 * @param {Test} test - The test to check.
 * @returns {boolean} True if the test is ready to be saved, false otherwise.
 */
export const isTestReadyToSave = test => {
  return test && isAllQuestionsAreInViewMode(test.questions);
};

/**
 * Checks if a test has been modified compared to its master data.
 *
 * @param {Test} test - The test to check.
 * @param {Array<Test>} testsMasterData - The master data for all tests.
 * @returns {boolean} True if the test has been modified, false otherwise.
 */
export const isTestModified = (test, testsMasterData) => {
  const masterTestData = testsMasterData.find(item => item.id === test.id);

  if (test.title !== masterTestData.title) {
    return true;
  }

  if (test.questions.length !== masterTestData.questions.length) {
    return true;
  }

  return !test.questions.every((question, index) =>
    deepEqual(question.qtiModel, masterTestData.questions[index].qtiModel)
  );
};

/**
 * Generates a new test title by finding the next available "Untitled <number>" title.
 *
 * @param {Array<Test>} tests - An array of test objects with title properties.
 * @returns {string} A new test title in the format "Untitled <number>".
 */
export const generateNewTestTitle = tests => {
  const existingTitles = tests.map(test => test.title);
  const untitledTitlesList = existingTitles.filter(title => title.startsWith('Untitled '));

  const usedNumbers = new Set(untitledTitlesList.map(title => Number(title.split(' ')[1])).filter(num => !isNaN(num)));

  for (let i = 1; i <= MAX_ALLOWED_TEST_TABS; i++) {
    if (!usedNumbers.has(i)) {
      return `Untitled ${i}`;
    }
  }
};

/**
 * Returns the adjacent test to the removed test, or the active test if the removed test is not found.
 *
 * @param {Array<Test>} tests - The array of tests
 * @param {Test} removedTest - The test that was removed
 * @param {Test} activeTest - The currently active test
 * @returns {Test|null} The adjacent test, or null if the removed test is not found
 */
export const getAdjacentTest = (tests, removedTest, activeTest) => {
  if (removedTest.id !== activeTest.id) return activeTest;

  const index = tests.findIndex(test => test.id === removedTest.id);
  if (index === -1) return null;

  return tests[index - 1] || tests[index + 1];
};
