import React, { useEffect } from 'react';
import ManageUsersTab from 'workspace/components/ManageUsersTab/ManageUsersTab';

const ManageUsersPage = () => {
  useEffect(() => {
    document.title = 'Manage Users';
  }, []);

  return (
    <>
      <ManageUsersTab />
    </>
  );
};
export default ManageUsersPage;
