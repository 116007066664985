/**
 * Returns a validation function based on the provided form validations.
 *
 * @param {Object} fieldValidations - An object containing validation rules for each field.
 * @returns {Function} A function that takes a field name and value, and returns an error message if the value is invalid.
 */
export const getValidationFunction = formValidations => {
  return (name, value) => {
    const validations = formValidations[name];

    if (!validations) {
      return undefined;
    }

    // Iterate over the validation rules
    for (const validation in validations) {
      // Check if the value is required
      switch (validation) {
        case 'required':
          if (!value) {
            return validations.required;
          }
          break;

        // Check if the value matches a pattern
        case 'pattern':
          if (!validations.pattern.value.test(value)) {
            return validations.pattern.errorMessage;
          }
          break;

        // Check if the value matches a specific length
        case 'length':
          if (value.length < validations.length.min || value.length > validations.length.max) {
            return validations.length.errorMessage;
          }
          break;

        // Check if the value matches a specific email format
        case 'email': {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
          if (!emailRegex.test(value)) {
            return validations.email;
          }
          break;
        }

        default:
          // You can add more validation types here or throw an error if unexpected
          console.warn(`Unknown validation type: ${validation}`);
          break;
      }
    }

    // If all validations pass, return undefined
    return undefined;
  };
};
