import { Navigate } from 'react-router-dom';
import { useAuth } from 'workspace/providers/auth/AuthContext';

const RoleBasedRoutes = ({ children, allowedRoles }) => {
  const { userData } = useAuth();

  if (userData && !allowedRoles?.includes(userData.role)) {
    return <Navigate to="/ws/managebooks" />;
  }

  return children;
};

export default RoleBasedRoutes;
