import { EditorState, Modifier } from 'draft-js';

/**
 * Checks if an entity is of type 'BLANK'.
 *
 * @param {string} entityKey - The key of the entity to check.
 * @param {ContentState} contentState - The current content state.
 * @returns {boolean} True if the entity is of type 'BLANK', false otherwise.
 */
const isBlankEntity = (entityKey, contentState) => contentState.getEntity(entityKey).getType() === 'BLANK';

/**
 * Gets an entity at a specific offset in a block.
 *
 * @param {ContentBlock} block - The block to get the entity from.
 * @param {number} offset - The offset to get the entity at.
 * @returns {string|null} The key of the entity at the specified offset, or null if no entity is found.
 */
const getEntityAtOffset = (block, offset) => block.getEntityAt(offset);

/**
 * Handles the delete key press event in the editor.
 *
 * @param {EditorState} editorState - The current editor state.
 * @returns {string} 'handled' if the delete key press was handled, 'not-handled' otherwise.
 */
export const handleDeleteKey = editorState => {
  // Get the current selection and content state
  const selectionState = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const blockKey = selectionState.getStartKey();
  const block = currentContent.getBlockForKey(blockKey);
  const startOffset = selectionState.getStartOffset();

  // If the cursor is at the start of the block, do not handle the delete key press
  if (startOffset === 0) return 'not-handled';

  // Get the entity after the cursor
  const afterEntity = getEntityAtOffset(block, startOffset + 1);

  // If the entity after the cursor is a 'BLANK' entity and the character before the cursor is a space,
  // handle the delete key press
  if (afterEntity && isBlankEntity(afterEntity, currentContent)) {
    if (block.getText()[startOffset] !== ' ') return 'not-handled';
    return 'handled';
  } else {
    return 'not-handled';
  }
};

/**
 * Handles the backspace key press event in the editor.
 *
 * @param {EditorState} editorState - The current editor state.
 * @returns {EditorState} The updated editor state after handling the backspace key press.
 */
export const handleBackspacekey = editorState => {
  // Get the current selection and content state
  const selectionState = editorState.getSelection();
  const currentContent = editorState.getCurrentContent();
  const blockKey = selectionState.getStartKey();
  const block = currentContent.getBlockForKey(blockKey);
  const startOffset = selectionState.getStartOffset();

  // Get the entity before the cursor
  const beforeEntity = getEntityAtOffset(block, Math.max(0, startOffset - 2));

  // If the entity before the cursor is a 'BLANK' entity, remove the entity and update the editor state
  if (beforeEntity && isBlankEntity(beforeEntity, currentContent)) {
    // Create a new selection that includes the entity to be removed
    const newSelection = selectionState.merge({
      anchorOffset: selectionState.getAnchorOffset() - 12,
      focusOffset: selectionState.getAnchorOffset(),
    });

    // Remove the entity from the content state
    const newContentState = Modifier.removeRange(currentContent, newSelection, 'backward');

    // Update the editor state with the new content state
    return EditorState.push(editorState, newContentState, 'remove-range');
  }
};
