import { Navigate } from 'react-router-dom';
import HomePage from '../pages/home-page/HomePage';
import FullLayout from '../pages/layouts/FullLayout';
import TestsPage from '../pages/TestsPage.js';
import YourQuestionsPage from '../pages/YourQuestionsPage';
import QuestionBanks from '../pages/QuestionBanks/QuestionBanks';
import QuestionTemplatePage from '../pages/QuestionTemplatePage';
import WelcomeScreen from '../pages/welcome-screen/WelcomeScreen';
import Discipline from '../pages/Discipline/Discipline';
import Booktab from '../pages/Booktab/Booktab';
import ProvidersWrapper from './ProvidersWrapper.js';
import AuthWrapper from './AuthWrapper.js';
import ProtectedRoutes from './ProtectedRoutes.js';

const TestBuilderRoutes = [
  {
    path: '/',
    element: (
      <ProvidersWrapper>
        <AuthWrapper>
          <ProtectedRoutes />
        </AuthWrapper>
      </ProvidersWrapper>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="home" />,
      },
      {
        path: 'home',
        element: (
          <FullLayout>
            <HomePage />
          </FullLayout>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="tests" />,
          },
          {
            path: 'tests',
            element: <TestsPage />,
          },
          {
            path: 'questions',
            element: <YourQuestionsPage />,
          },
          {
            path: 'questionbanks',
            element: <QuestionBanks />,
          },
          {
            path: 'questiontemplate',
            element: <QuestionTemplatePage />,
          },
        ],
      },
      {
        path: 'welcomescreen',
        element: <WelcomeScreen />,
      },
      {
        path: 'discipline',
        element: <Discipline />,
      },
      {
        path: 'booktab',
        element: <Booktab />,
      },
    ],
  },
];

export default TestBuilderRoutes;
