import { useEffect, useState } from 'react';
import { AppProvider } from '../context/AppContext';
import { AuthProvider } from '../context/AuthContext';
import QuestionsProvider from '../providers/Questions/QuestionsProvider';
import ApplicationLoader from '../components/common/ApplicationLoader';
import Loader from '../../common/components/loader/Loader';
import auth from '../services/Auth';

function loadScript() {
  return new Promise(resolve => {
    const url = process.env.REACT_APP_AUTH_Login_URL;

    const script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.async = false;
    script.id = 'pi_session';

    if (!document.getElementById('pi_session')) {
      document.body.insertBefore(script, document.body.firstChild);
    }

    script.onload = () => {
      resolve('loaded');
    };
  });
}

function ProvidersWrapper({ children }) {
  const [iesScriptLoaded, setIesScriptloaded] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes('ws')) {
      loadIESScript();
    }
  }, []);

  const loadIESScript = async () => {
    try {
      await loadScript();
      auth.initialize();
      setIesScriptloaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  return iesScriptLoaded ? (
    <AuthProvider>
      <AppProvider>
        <QuestionsProvider>{children}</QuestionsProvider>
        <ApplicationLoader />
      </AppProvider>
    </AuthProvider>
  ) : (
    <Loader />
  );
}

export default ProvidersWrapper;
