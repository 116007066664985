export const ANSWER_KEYS = {
  NONE: 'NONE',
  SAMEFILE: 'SAMEFILE',
  SEPARATEFILE: 'SEPARATEFILE',
};

export const FILE_FORMATS = {
  DOC: 'doc',
  PDF: 'pdf',
  BLACKBOARD_POOL_MANAGER: 'bbpm',
  BLACKBOARD_TEST_MANAGER: 'bbtm',
  QTI_21: 'qti21',
};

/**
 * exportFileFormats
 * An array of objects representing the available file formats for exporting data.
 * Each object contains a `value`, `text`, and `fileExtension` property.
 */
export const exportFileFormats = [
  { value: FILE_FORMATS.DOC, text: 'MS Word', fileExtension: 'doc' },
  { value: FILE_FORMATS.PDF, text: 'PDF', fileExtension: 'pdf' },
  {
    value: FILE_FORMATS.BLACKBOARD_POOL_MANAGER,
    text: 'Blackboard Pool manager',
    fileExtension: 'zip',
    fileNameDescription: 'BBpoolmgr',
  },
  {
    value: FILE_FORMATS.BLACKBOARD_TEST_MANAGER,
    text: 'Blackboard Test manager',
    fileExtension: 'zip',
    fileNameDescription: 'BBtestmgr',
  },
  { value: FILE_FORMATS.QTI_21, text: 'QTI 2.1', fileExtension: 'zip', fileNameDescription: 'QTImgr' },
];

/**
 * answerAreas
 * An array of objects representing the available answer area options.
 * Each object contains a `value`, `isDisabled`, and `text` property.
 */
export const answerAreas = [
  { value: 'NONE', isDisabled: false, text: 'None' },
  { value: 'BETWEENQUESTIONS', isDisabled: false, text: 'Between questions' },
  { value: 'LEFTSIDE', isDisabled: false, text: 'Left side of the page' },
  { value: 'LASTPAGE', isDisabled: false, text: 'Blank last page' },
];

/**
 * answerKeys
 * An array of objects representing the available answer key options.
 * Each object contains a `value` and `text` property.
 */
export const answerKeys = [
  { value: ANSWER_KEYS.NONE, text: 'None' },
  { value: ANSWER_KEYS.SAMEFILE, text: 'Same file' },
  { value: ANSWER_KEYS.SEPARATEFILE, text: 'Separate file' },
];

/**
 * margins
 * An array of objects representing the available margin options.
 * Each object contains a `value` and `text` property.
 */
export const margins = [
  { value: '0.5', text: '0.5 inch' },
  { value: '1.0', text: '1 inch' },
  { value: '1.5', text: '1.5 inch' },
];

/**
 * An array of page number positions.
 * Each object contains a `value` and `text` property.
 */
export const pageNumbers = [
  { value: 'BOTTOMMIDDLE', text: 'Bottom middle' },
  { value: 'BOTTOMRIGHT', text: 'Bottom right' },
  { value: 'TOPRIGHT', text: 'Top right' },
];
