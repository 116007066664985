import Essay from './Essay';
import Matching from './Matching';
import MultipleChoice from './MultipleChoice';
import TrueFalse from './TrueFalse';
import MultipleResponse from './MultipleResponse';
import FillInBlanks from './FillInBlanks';
import { QUESTION_TYPES } from 'common/constants/question-constants';

export const QUESTION_COMPONENTS = {
  [QUESTION_TYPES.TrueFalse]: TrueFalse,
  [QUESTION_TYPES.Essay]: Essay,
  [QUESTION_TYPES.Matching]: Matching,
  [QUESTION_TYPES.MultipleChoice]: MultipleChoice,
  [QUESTION_TYPES.MultipleResponse]: MultipleResponse,
  [QUESTION_TYPES.FillInBlanks]: FillInBlanks,
};
