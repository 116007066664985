import httpInterceptor from '../../common/httpHelper/httpHelper';

const url = process.env.REACT_APP_API_URL;

export const getUserQuestionFolders = () => {
  return httpInterceptor
    .get(`${url}/my/questionfolders`)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const getUserQuestionFoldersRoot = () => {
  return httpInterceptor
    .get(`${url}/my/questionfoldersroot`)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const saveUserQuestionFolder = folder => {
  return httpInterceptor
    .post(`${url}/my/questionfolders`, folder)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const updateUserQuestionFolders = folders => {
  return httpInterceptor
    .put(`${url}/my/questionfolders`, folders)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const getChildQuestionFolders = folderId => {
  return httpInterceptor
    .get(`${url}/my/questionfolders/${folderId}/folders`)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const getUserQuestions = folderId => {
  return httpInterceptor
    .get(`${url}/my/questions?folderId=${folderId}`)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};

export const getAllQuestions = async (bookid, nodeId, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestUrl = `${url}/books/${bookid}/nodes/${nodeId}/questions${queryString ? `?${queryString}` : ''}`;

  try {
    const response = await httpInterceptor.get(requestUrl);
    return response?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const swapQuestionBetweenFolders = (sourceFolderId, destinationFolderId, questionId) => {
  const endpoint = `${url}/my/folders/${sourceFolderId}/folders/${destinationFolderId}/questions/${questionId}`;

  return httpInterceptor
    .put(endpoint)
    .then(response => {
      return response?.data;
    })
    .catch(error => {
      return Promise.reject({
        type: 'error',
        message: error,
      });
    });
};
