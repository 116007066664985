import { extractErrorDetails } from 'common/utils';
import axios from './axios';

import questions from '../data/questions';

export async function getFolders(bookId, folderId) {
  try {
    const response = await axios.get(`/ws/books/${bookId}/folders/${folderId}/folders`);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function getRootFolderId(bookId) {
  try {
    const response = await axios.get(`/ws/books/${bookId}/rootFolder`);
    return response.data.guid;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function createFolder(bookId, payload) {
  try {
    const response = await axios.post(`/ws/books/${bookId}/folders`, payload);
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function updateFolder(bookId, payload) {
  try {
    const folderId = payload.guid;
    const response = await axios.put(`/ws/books/${bookId}/folders/${folderId}`, payload);
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function deleteFolder(bookId, folderId) {
  try {
    const response = await axios.delete(`/ws/books/${bookId}/folders/${folderId}`);
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function getQuestions(bookId, folderId) {
  try {
    return Promise.resolve(questions);
    // const response = await axios.get(`/ws/books/${bookId}/folder/${folderId}/questions`);
    // return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function saveQuestions(bookId, folderId, payload) {
  try {
    const response = await axios.post(`/ws/books/${bookId}/folder/${folderId}/questions`, payload);
    return response.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}
