import React from 'react';

function NoDragWrapper({ children, node }) {
  const handleMouseEnter = () => {
    node.noDrag = true;
  };

  const handleMouseLeave = () => {
    node.noDrag = false;
  };

  return React.Children.map(children, child => {
    return React.cloneElement(child, { onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave });
  });
}

export default NoDragWrapper;
