import ReadOnlyActionButtons from '../ReadOnlyActionButtons';
import { sanitizedData } from 'common/utils';
import '../questions.css';

const EssayReadOnly = props => {
  const { questionNode, questionNodeIndex, showActionButtons = false, onEdit, onDelete } = props;

  const { Caption } = questionNode.qtiModel;

  return (
    <div className="d-flex align-items-center question-container">
      <div className="flex-grow-1 d-flex align-items-center flex-wrap">
        <div className="d-flex align-items-start gap-2 w-100">
          {questionNodeIndex !== undefined && <div>{questionNodeIndex + 1})</div>}
          <div className="question-content flex-grow-1" dangerouslySetInnerHTML={sanitizedData(Caption)}></div>
        </div>
      </div>

      {showActionButtons && <ReadOnlyActionButtons index={questionNodeIndex} onEdit={onEdit} onDelete={onDelete} />}
    </div>
  );
};

export default EssayReadOnly;
