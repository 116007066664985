import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { saveTestFolder } from '../../services/testfolder.service';
import { toastify } from '../../../common/components/Toastify';
import { updateTestFolder, updateTest } from '../../services/testfolder.service';
import { useAppContext } from '../../context/AppContext';
import { getUserBooks, getUserBooksByID } from '../../services/book.service';
import { getPublisherTestsByBookId } from '../../services/testcreate.service';
import TestFolderTreeView from './TestFolderTreeview/TestFolderTreeView';
import AddFolder from '../AddFolder';
import { getUserTestFolders } from '../../services/testfolder.service';
import { getFolderTests } from '../../services/testcreate.service';

const TestFolder = ({ userId }) => {
  const {
    selectedTest,
    savedFolders,
    setSavedFolders,
    rootFolderGuid,
    fetchUserFolders,
    setClickedNodes,
    dispatchEvent,
  } = useAppContext();
  const [editFolderName, setEditFolderName] = useState('');
  // const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [updateKey, setUpdateKey] = useState(0);
  const [selectedFolderGuid, setSelectedFolderGuid] = useState(null);
  const [parentFolderGuid, setParentFolderGuid] = useState('');
  const [bookOpenStates, setBookOpenStates] = useState({});
  const [bookTests, setBookTests] = useState({});
  const [selectedBookIds, setSelectedBookIds] = useState([]);
  const [bookTitles, setBookTitles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const savedFoldersFromStorage = JSON.parse(localStorage.getItem('savedFolders'));
    if (savedFoldersFromStorage) {
      setSavedFolders(savedFoldersFromStorage);
    }

    // setInitialFetchDone(true);
  }, []);

  // useEffect(() => {
  //   if (initialFetchDone) {
  //     fetchUserFolders();
  //   }
  // }, [initialFetchDone]);

  useEffect(() => {
    fetchUserFolders();
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchUserBooks();
    }
  }, [isOpen]);

  const fetchUserBooks = async () => {
    try {
      const bookIds = await getUserBooks();
      setSelectedBookIds(bookIds);
      const bookDetails = await Promise.all(
        bookIds.map(async bookId => {
          const book = await getUserBooksByID(bookId);
          return book;
        })
      );
      setBookTitles(bookDetails);
    } catch (error) {
      console.error('Error fetching user books:', error);
      toastify.showErrorToast(error?.message);
    }
  };

  const handleSaveFolder = async (folderName, isEditing, parentGuid) => {
    const trimmedFolderName = folderName.trim();
    if (!trimmedFolderName) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.FolderNameCannotBeEmpty' }));
      return;
    }
    const folderNameLower = folderName.trim().toLowerCase();
    const migratedTestsLower = 'migrated tests'.toLowerCase();
    if (folderName.trim() !== '') {
      try {
        if (folderNameLower === migratedTestsLower) {
          toastify.showErrorToast(intl.formatMessage({ id: 'error.Folder.name.MigratedTests' }));
          return;
        }
        const maxSequence = savedFolders.reduce((max, folder) => {
          return folder.sequence > max ? folder.sequence : max;
        }, 1);
        const newSequence = maxSequence + 1;

        if (isEditing) {
          // If editing, update the folder
          if (parentGuid == 0) {
            parentGuid = rootFolderGuid;
          }
          const [childFolders, childTests] = await Promise.all([
            getUserTestFolders(parentGuid),
            getFolderTests(parentGuid),
          ]);

          const childNodes = childFolders.concat(childTests);
          const editedChildFolderIndex = childNodes.findIndex(folder => folder.title === editFolderName);
          const editedFolder = childNodes[editedChildFolderIndex];
          const updatedFolderData = {
            guid: editedFolder.guid,
            parentId: editedFolder.parentId,
            sequence: editedFolder.sequence,
            title: folderName.trim(),
          };
          let updateFolder = {};
          try {
            updateFolder = await updateTestFolder(updatedFolderData);
            // const existingChildNodes = savedFolders.filter(node => node.guid !== updateFolder.guid);
            // const updatedFolders = [...existingChildNodes, updateFolder];
            // setSavedFolders(updatedFolders);
            // localStorage.setItem('savedFolders', JSON.stringify(updatedFolders));
            setUpdateKey(updateKey + 1);
            setClickedNodes([]);
            toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.update' }));
          } catch (error) {
            console.error('Error updating folder:', error);
            if (error?.message?.response?.request?.status === 409) {
              toastify.showErrorToast(error.message.response.data.message);
            } else {
              toastify.showErrorToast(intl.formatMessage({ id: 'error.folder.update.failed' }));
            }
            return;
          }
        } else {
          const newFolderData = {
            parentId: rootFolderGuid,
            sequence: newSequence,
            title: folderName.trim(),
          };
          const savedFolder = await saveTestFolder(newFolderData, userId);
          const updatedFolders = [...savedFolders, savedFolder];
          setSavedFolders(updatedFolders);
          localStorage.setItem('savedFolders', JSON.stringify(updatedFolders));
          setUpdateKey(updateKey + 1);

          toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.save' }));
        }
        setEditFolderName('');
        // Fetch the updated folders immediately after saving or updating
        fetchUserFolders();
      } catch (error) {
        console.error('Error saving folder:', error);
        if (error?.message?.response?.request?.status === 409) {
          toastify.showErrorToast(error.message.response.data.message);
        } else {
          toastify.showErrorToast(intl.formatMessage({ id: 'error.failedtosavefolder' }));
        }
      }
    }
  };

  const handleFolderSelect = (folderName, parentId) => {
    setEditFolderName(folderName);
    setParentFolderGuid(parentId);
  };

  const handleAddFolderClose = () => {
    setEditFolderName('');
    setUpdateKey(updateKey + 1);
  };

  const onNodeUpdate = async changedNode => {
    try {
      await updateTestFolder(changedNode);
      // fetchUserFolders();
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.rearrange' }));
    } catch (error) {
      console.error('Error rearranging folder:', error);
      if (error?.message?.response?.request?.status === 409) {
        toastify.showErrorToast(error.message.response.data.message);
        fetchUserFolders();
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.failedtorearrangefolder' }));
      }
    }
  };

  const onNodeUpdateTest = async (sourceId, destinationId, testId) => {
    try {
      await updateTest((sourceId = sourceId === 0 ? rootFolderGuid : sourceId), destinationId, testId);

      toastify.showSuccessToast(intl.formatMessage({ id: 'success.Testrearranged.successfully' }));
    } catch (error) {
      console.error('Error rearranging test:', error);
      if (error?.message?.response?.request?.status === 409) {
        toastify.showErrorToast(error.message.response.data.message);
        fetchUserFolders();
      } else if (error?.message?.response?.request?.status === 400) {
        toastify.showErrorToast(error.message.response.data.message);
        fetchUserFolders();
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.failed.To.rearrangetest' }));
      }
    }
  };

  const handleMigratedTestView = node => {
    console.log('Viewing migrated tests for node:', node);
    node.ismigrated = true;
    dispatchEvent('HANDLE_VIEW_OR_EDIT_TEST_CLICK', node);
  };

  const handleGetPublisherTests = async bookId => {
    try {
      if (!selectedBookIds || selectedBookIds.length === 0) {
        console.error('No book IDs selected.');
        return;
      }

      const tests = await getPublisherTestsByBookId(bookId);
      setBookTests(prevTests => ({
        ...prevTests,
        [bookId]: tests,
      }));
    } catch (error) {
      console.error(`Error fetching tests for book ID ${bookId}:`, error);
    }
  };

  const handleBookClick = bookId => {
    if (!bookOpenStates[bookId]) {
      handleGetPublisherTests(bookId);
    }
    toggleBookOpenState(bookId);
  };

  const toggleBookOpenState = bookId => {
    setBookOpenStates(prevState => ({
      ...prevState,
      [bookId]: !prevState[bookId],
    }));
  };

  return (
    <div className="p-2 flex-column">
      <AddFolder
        name={editFolderName}
        parentId={parentFolderGuid}
        onFolderAdd={handleSaveFolder}
        onClose={handleAddFolderClose}
      />
      <div className="your-test-list flex-grow-1">
        <div className="maigratedtests">
          <div className="testbtn" tabIndex="0">
            {isOpen ? (
              <i className="fa fa-caret-down" onClick={() => setIsOpen(!isOpen)}></i>
            ) : (
              <i className="fa fa-caret-right" onClick={() => setIsOpen(!isOpen)}></i>
            )}
            <span style={{ marginLeft: '9px' }}>
              <FormattedMessage id="migratedtests" />
            </span>
          </div>
        </div>
        {isOpen && bookTitles.length > 0 && (
          <div className="book-dropdown" tabIndex="0">
            {bookTitles.map((book, index) => (
              <div
                key={book.guid}
                style={{
                  borderBottom: index !== bookTitles.length - 1 ? '2px solid white' : 'none',
                  paddingBottom: '5px',
                }}
              >
                <button className="testbtn" tabIndex="0">
                  {bookOpenStates[book.guid] ? (
                    <i className="fa fa-caret-down" onClick={() => handleBookClick(book.guid)}></i>
                  ) : (
                    <i className="fa fa-caret-right" onClick={() => handleBookClick(book.guid)}></i>
                  )}

                  <span style={{ marginLeft: '9px' }}>{book.title}</span>
                </button>
                {bookOpenStates[book.guid] && bookTests[book.guid] && bookTests[book.guid].length > 0 && (
                  <div className="test-dropdown">
                    {bookTests[book.guid].map((test, index) => (
                      <div
                        key={index}
                        className="test-item"
                        tabIndex="0"
                        style={{
                          borderBottom: index !== bookTests[book.guid].length - 1 ? '2px solid white' : 'none',
                        }}
                      >
                        {test.title}
                        <button
                          className={`info ${selectedTest?.testId === test.guid ? 'selected' : ''}`}
                          title={intl.formatMessage({ id: 'message.view', defaultMessage: 'View' })}
                          onClick={() => handleMigratedTestView(test)}
                        >
                          <i className="bi bi-eye"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {savedFolders && savedFolders.length > 0 && (
          <TestFolderTreeView
            key={updateKey}
            folders={savedFolders}
            onFolderSelect={handleFolderSelect}
            onNodeUpdate={onNodeUpdate}
            onNodeUpdateTest={onNodeUpdateTest}
            rootFolderGuid={rootFolderGuid}
            selectedFolderGuid={selectedFolderGuid}
            tabIndex="0"
          />
        )}
      </div>
    </div>
  );
};

export default TestFolder;
