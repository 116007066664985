import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import BookCard from './BookCard';
import CreateOrEditBookModal from './CreateOrEditBookModal';
import BookDetailsModal from './BookDetailsModal';
import SearchBox from 'common/components/SearchBox/SearchBox';
import { toastify } from 'common/components/Toastify';
import { BOOK_STATUS } from 'workspace/constants';
import { deleteBook } from 'workspace/api/books.service';
import { useLoader } from 'common/providers/LoaderProvider';

const BooksList = ({ books, onBookSelect, searchTerm, onSearchChange, fetchBooks, loading, updateBookStatus }) => {
  const intl = useIntl();

  const [activeBook, setActiveBook] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const [showCreateOrEditBookModal, setShowCreateOrEditBookModal] = useState(false);

  useEffect(() => {
    fetchBooks();
  }, []);

  const handleActions = (action, book, status) => {
    switch (action) {
      case 'details':
        setActiveBook(book);
        setShowDetailsModal(true);
        break;
      case 'edit':
        setActiveBook(book);
        setShowCreateOrEditBookModal(true);
        break;
      case 'delete':
        handleDeleteBook(book);
        break;
      case 'open':
        onBookSelect(book);
        break;
      case BOOK_STATUS.PUBLISHED:
      case BOOK_STATUS.REPUBLISHED:
      case BOOK_STATUS.UNPUBLISHED:
        updateBookStatus(book, status);
        break;
      default:
        console.error(`Unknown action: ${action}`);
    }
  };

  const handleDeleteBook = async book => {
    try {
      await deleteBook(book.guid);
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.bookdelete' }));
      showLoader();
      fetchBooks();
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  const handleCreateBookClick = () => {
    setShowCreateOrEditBookModal(true);
    setActiveBook(null);
  };

  const handleClose = () => {
    setActiveBook(null);
    setShowDetailsModal(false);
    setShowCreateOrEditBookModal(false);
  };

  // Filter books based on search term
  const filteredBooks = books.filter(book =>
    `${book.createdBy} ${book.title} ${book.discipline}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Row>
        <Col xs={10} className="book-search-searchbox">
          <SearchBox searchText={searchTerm} placeholder="Search Books" onSearch={onSearchChange} />
        </Col>
        <Col xs={2} className="align-right">
          <Button onClick={handleCreateBookClick} className="add-book">
            <FormattedMessage id="ws.addbook" />
          </Button>
        </Col>
      </Row>
      <Row className="d-flex justify-content-start books-list-row">
        {!loading && books.length === 0 && (
          <div className="no-matching-books d-flex justify-content-center align-items-center" tabIndex="0">
            <FormattedMessage id="ws.noBooksMessage" defaultMessage="No books found" />
          </div>
        )}
        {filteredBooks.map((book, index) => (
          <Col xs={12} md={6} lg={4} key={book.guid} className="book-custom-col mb-4">
            <BookCard book={book} handleActions={handleActions} />
          </Col>
        ))}
        {filteredBooks.length === 0 && books.length != 0 && (
          <div className="no-matching-books d-flex justify-content-center align-items-center" tabIndex="0">
            <FormattedMessage id="noMatchingBooksMessage" defaultMessage="No matching books found" />
          </div>
        )}
      </Row>
      {showCreateOrEditBookModal && (
        <CreateOrEditBookModal
          activeBook={activeBook} // Pass the book to be edited
          reload={fetchBooks}
          handleClose={handleClose}
        />
      )}
      {showDetailsModal && activeBook && <BookDetailsModal book={activeBook} handleClose={handleClose} />}
    </>
  );
};

export default BooksList;
