import DOMPurify from 'dompurify';
import QtiService from 'common/utils/qti-converter';
import { deepClone } from 'common/utils';
import { QUESTION_TYPES } from 'common/constants/question-constants';
import { filterExtendedMetadata } from '../question-utils';

/**
 * Process question data based on its type
 *
 * @param {object} data - Question data
 * @param {object} format - Format options
 * @returns {object} Processed question data
 */
const constructFormData = (question, data, format) => {
  switch (question.quizType) {
    case QUESTION_TYPES.TrueFalse:
      return {
        Caption: data.Caption,
        Options: data.Options,
        CorrectAnswer: data.CorrectAnswer,
        orientation: format.orientation,
      };

    case QUESTION_TYPES.Essay:
      return {
        Caption: data.Caption,
        RecommendedAnswer: DOMPurify.sanitize(data.RecommendedAnswer),
        expectedLines: format.expectedLines,
      };

    case QUESTION_TYPES.FillInBlanks:
      return {
        Caption: data.Caption,
        Blanks: data.blanks.map(blank => ({
          ...blank,
          answer: DOMPurify.sanitize(blank.answer),
        })),
      };

    case QUESTION_TYPES.Matching:
      return {
        Caption: data.Caption,
        Options: data.Options,
      };

    case QUESTION_TYPES.MultipleResponse:
      return {
        Caption: data.Caption,
        Options: data.optionsRecords.map(x => x.value),
        CorrectAnswer: data.optionsRecords.map(x => x.checked),
        orientation: format.orientation,
      };

    case QUESTION_TYPES.MultipleChoice:
      return {
        Caption: data.Caption,
        Options: data.Options,
        CorrectAnswer: data.CorrectAnswer,
        orientation: format.orientation,
      };

    default:
      throw new Error(`Unsupported question type: ${data.type}`);
  }
};

/**
 * Custom hook which handles form submission for a question.
 *
 * @param {Object} questionNode - The question node object.
 * @param {Object} questionData - The question data object.
 * @param {Object} formatAndExtendedMetadata - The format and extended metadata object.
 * @returns {Object} An object with a handleSubmit function.
 */
const useFormSubmit = (questionNode, questionData, formatAndExtendedMetadata, index, onSubmit) => {
  const handleSubmit = event => {
    event.preventDefault();
    const question = deepClone(questionNode);

    // Construct question related form data
    const formData = constructFormData(questionNode, questionData, formatAndExtendedMetadata);

    // Update question model
    question.qtiModel = {
      ...formData,
      extendedMetadata: filterExtendedMetadata(formatAndExtendedMetadata),
      isInEditView: false,
    };

    // Convert JSON to XML and update questionNode data
    question.qtixml = QtiService.getQtiXML(question);

    // calling onDelete callback function
    onSubmit({ question, index });
  };

  return {
    handleSubmit,
  };
};

export default useFormSubmit;
