import { useState } from 'react';
import BooksList from 'workspace/components/ManageBooksTab/BooksList';
import ManageQuestionsTab from '../ManageQuestions/ManageQuestionsTab';
import { getBooks } from 'workspace/api/books.service';
import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import './manage-books-tab.css';
// import bookData from 'workspace/data/books'; // Ensure this path is correct

const ManageBooksTab = () => {
  const [selectedBook, setSelectedBook] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [books, setBooks] = useState([]);
  const { showLoader, hideLoader } = useLoader();
  const [loading, setLoading] = useState(true);

  const fetchBooks = async () => {
    try {
      showLoader();
      const booksData = await getBooks();
      setBooks(booksData);
      setLoading(true);
    } catch (err) {
      if (err.status === 404) {
        setBooks([]);
        return;
      } else {
        toastify.showErrorToast('Unable to fetch book data at this time. Please try again later.');
      }
    } finally {
      hideLoader();
      setLoading(false);
    }
  };

  const updateBookStatus = (book, newStatus) => {
    setBooks(prevBooks => {
      return prevBooks.map(prevBook => (prevBook.guid === book.guid ? { ...prevBook, status: newStatus } : prevBook));
    });
  };

  const handleBookSelect = book => {
    setSelectedBook(book);
  };

  const hideBooks = () => {
    setSelectedBook(null);
  };

  const handleSearchChange = value => {
    setSearchTerm(value); // Update search term in parent component
  };

  return selectedBook ? (
    <ManageQuestionsTab selectedBook={selectedBook} hideBooks={hideBooks} />
  ) : (
    <div className="manage-books-tab-container">
      <BooksList
        books={books}
        loading={loading}
        searchTerm={searchTerm}
        onBookSelect={handleBookSelect}
        onSearchChange={handleSearchChange}
        updateBookStatus={updateBookStatus}
        fetchBooks={fetchBooks}
      />
    </div>
  );
};

export default ManageBooksTab;
