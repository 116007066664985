import React from 'react';
import { useIntl } from 'react-intl';

const ReadOnlyActionButtons = ({ index, onEdit, onDelete }) => {
  const intl = useIntl();

  const handleEditClick = event => {
    event.preventDefault();

    // calling onEdit callback function
    onEdit(index);
  };

  const handleDeleteClick = event => {
    event.preventDefault();

    // calling onDelete callback function
    onDelete(index);
  };

  return (
    <div className="mr-7 d-flex align-items-center d-flex justify-content-end align-self-start">
      <button
        className="editbtn"
        onClick={handleEditClick}
        title={intl.formatMessage({ id: 'message.edit', defaultMessage: 'Edit' })}
      >
        <i className="bi bi-pencil-fill"></i>
      </button>
      <button
        className="deletebtn"
        onClick={handleDeleteClick}
        title={intl.formatMessage({ id: 'message.remove', defaultMessage: 'Remove' })}
      >
        <i className="bi bi-trash"></i>
      </button>
    </div>
  );
};

export default ReadOnlyActionButtons;
