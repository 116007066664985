import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = ({ config, onConfirm, onCancel }) => {
  const { title, message, cancelText, confirmText } = config;
  return (
    <Modal show={true} onHide={onCancel} backdrop="static" keyboard={false}>
      {title && (
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
