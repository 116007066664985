import React from 'react';
import { useIntl } from 'react-intl';
import { isViewButtonEnabled } from 'common/components/questions/question-utils';
import useFormSubmit from './hooks/useFormSubmit';

const EditViewActionButtons = ({
  index,
  questionNode,
  questionData,
  formatAndExtendedMetadata,
  onSubmit,
  onDelete,
}) => {
  const intl = useIntl();

  // this hook to be placed after all other hooks
  const { handleSubmit } = useFormSubmit(questionNode, questionData, formatAndExtendedMetadata, index, onSubmit);

  const enableViewButton = isViewButtonEnabled(questionNode.quizType, questionData);

  const handleDelete = event => {
    event.preventDefault();

    // calling onDelete callback function
    onDelete(index);
  };

  return (
    <div className="mb-1 d-flex justify-content-end">
      <div className="tooltip-wrapper" title={intl.formatMessage({ id: 'message.view', defaultMessage: 'View' })}>
        <button
          className={`savelink ${!enableViewButton ? 'disabled-link' : ''}`}
          onClick={handleSubmit}
          tabIndex={enableViewButton ? 0 : -1}
          disabled={!enableViewButton}
          aria-label="view"
        >
          <i className="bi bi-eye"></i>
        </button>
      </div>
      <button
        className="deletelink"
        onClick={handleDelete}
        title={intl.formatMessage({ id: 'message.remove', defaultMessage: 'Remove' })}
      >
        <i className="bi bi-trash"></i>
      </button>
    </div>
  );
};

export default EditViewActionButtons;
