import { FormattedMessage } from 'react-intl';
import { Container, Nav, Navbar } from 'react-bootstrap';

const Header = ({ children, hideHelp, workspaceName }) => {
  return (
    <header className="App-header">
      <Navbar bg="light" data-bs-theme="light" expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand className="brand">
            <h1 className="pearson-heading">
              <FormattedMessage id="pearsonheader.title" />
              {workspaceName ? ` - ${workspaceName}` : ''}
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              {children}
              {!hideHelp && (
                <Nav.Link id="help-icon" aria-label="help button to activate press enter">
                  <FormattedMessage id="helpText" />
                  &nbsp;
                  <i className="bi bi-question-circle"></i>
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
export default Header;
