import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { Editor, EditorProvider, Toolbar } from 'lib/rte';
import EditViewActionButtons from '../EditViewActionButtons';
import { toastify } from 'common/components/Toastify';
import FormatAndAddExtendedMetadataSection from '../FormatAndAddExtendedMetadataSection';
import useFormatAndExtendedMetadata from '../hooks/useFormatAndExtendedMetadata';

const MAX_OPTIONS_COUNT = process.env.REACT_APP_ANSWER_OPTIONS_COUNT;

const generateOptions = (options, answers = []) => {
  if (!options) return [{ id: uuidv4() }, { id: uuidv4() }, { id: uuidv4() }, { id: uuidv4() }];

  return options.map((option, index) => ({
    id: uuidv4(),
    value: option,
    checked: answers[index] || false,
  }));
};

const MultipleResponseEditView = ({ questionNode, questionNodeIndex, userMetadata, onSubmit, onDelete }) => {
  const { Caption, Options, CorrectAnswer } = questionNode.qtiModel;
  const intl = useIntl();

  const [questionData, setQuestionData] = useState({
    Caption: Caption ?? '',
    optionsRecords: generateOptions(Options, CorrectAnswer),
  });

  const [formatAndExtendedMetadata, setFormatAndExtendedMetadata] = useFormatAndExtendedMetadata(questionNode);

  const handleOptionChecked = (e, item) => {
    const { checked } = e.target;

    const options = questionData.optionsRecords.map(option => {
      if (option.id === item.id) {
        option.checked = checked;
      }

      return option;
    });

    setQuestionData(prev => ({
      ...prev,
      optionsRecords: options,
    }));
  };

  const handleOptionValueChange = (value, item) => {
    const options = questionData.optionsRecords.map(option => {
      if (option.id === item.id) {
        option.value = value;
      }

      return option;
    });

    setQuestionData(prev => ({
      ...prev,
      optionsRecords: options,
    }));
  };

  const handleAddOption = () => {
    if (questionData.optionsRecords.length < MAX_OPTIONS_COUNT) {
      setQuestionData(prev => ({
        ...prev,
        optionsRecords: [...prev.optionsRecords, { id: uuidv4(), value: '', checked: false }],
      }));
    } else {
      const msg = intl.formatMessage({ id: 'warning.moreoptions' }, { count: MAX_OPTIONS_COUNT });
      toastify.showWarningToast(msg);
    }
  };

  const handleRemoveOption = item => {
    if (questionData.optionsRecords.length > 2) {
      const options = questionData.optionsRecords.filter(option => option.id !== item.id);

      setQuestionData(prev => ({
        ...prev,
        optionsRecords: options,
      }));
    }
  };

  const handleChange = e => {
    const { name, value } = e.target || e;

    setQuestionData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="m-2 border rounded p-3 bg-light">
      <EditorProvider>
        <Toolbar />
        <Form className="editmode paddingright10">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="mb-1">
              <b>{intl.formatMessage({ id: 'label.multipleResponse.title' })}</b>
            </Form.Label>

            <Editor
              name="Caption"
              value={questionData.Caption}
              placeholder={intl.formatMessage({ id: 'placeholder.multipleResponse.caption' })}
              className="mb-4"
              onChange={value => handleChange({ name: 'Caption', value })}
            />

            <Form.Group className="mb-1 mt-3 d-flex flex-wrap">
              {questionData.optionsRecords.map((optItem, index) => {
                return (
                  <Form.Group key={index} className="mc-flex-row mb-2">
                    <div className="mc-col mc-col-1" tabIndex="0">
                      <Form.Check
                        type="checkbox"
                        checked={optItem.checked}
                        className="item-1"
                        name="CorrectAnswer"
                        onChange={e => handleOptionChecked(e, optItem)}
                      />
                    </div>
                    <div className="mc-col mc-col-2">
                      <Editor
                        key={optItem.id}
                        name={index}
                        value={optItem.value}
                        placeholder={intl.formatMessage({ id: 'placeholder.multipleResponse.option' })}
                        className="item-2"
                        onChange={value => handleOptionValueChange(value, optItem)}
                      />
                    </div>
                    <Button
                      className="opt-close-button highlight-icon-button"
                      variant="link"
                      title={intl.formatMessage({
                        id: 'message.remove',
                        defaultMessage: 'Delete Option',
                      })}
                      onClick={() => handleRemoveOption(optItem)}
                      disabled={optItem.checked || questionData.optionsRecords.length <= 2}
                    >
                      <i className="bi bi-x-lg"></i>
                    </Button>
                  </Form.Group>
                );
              })}
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button onClick={handleAddOption}>
                <FormattedMessage id="addOptionsforMultiplechoice" />
              </Button>
            </div>
          </Form.Group>

          <FormatAndAddExtendedMetadataSection
            data={formatAndExtendedMetadata}
            setData={setFormatAndExtendedMetadata}
            userMetadata={userMetadata}
          />
        </Form>

        <EditViewActionButtons
          index={questionNodeIndex}
          questionNode={questionNode}
          questionData={questionData}
          formatAndExtendedMetadata={formatAndExtendedMetadata}
          onSubmit={onSubmit}
          onDelete={onDelete}
        />
      </EditorProvider>
    </div>
  );
};

export default MultipleResponseEditView;
