import { AxiosError } from 'axios';
import httpInterceptor from '../../common/httpHelper/httpHelper';

const url = process.env.REACT_APP_API_URL;

export const uploadFile = async data => {
  try {
    const response = await httpInterceptor.post(`${url}/images`, data);
    return response.data;
  } catch (error) {
    throw error instanceof AxiosError ? error.message : error;
  }
};
