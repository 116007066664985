import { AxiosError } from 'axios';
import httpInterceptor from '../../common/httpHelper/httpHelper';

const url = process.env.REACT_APP_API_URL;

export const getAllDisciplines = async () => {
  try {
    const response = await httpInterceptor.get(`${url}/disciplines`);
    return response.data;
  } catch (error) {
    throw error instanceof AxiosError ? error.message : error;
  }
};

export const getUserDisciplines = async () => {
  try {
    const response = await httpInterceptor.get(`${url}/settings/disciplines`);
    return response.data;
  } catch (error) {
    throw error instanceof AxiosError ? error.message : error;
  }
};

export const saveUserDiscipline = async disciplines => {
  try {
    const response = await httpInterceptor.post(`${url}/settings/disciplines`, disciplines);
    return response.data;
  } catch (error) {
    throw error instanceof AxiosError ? error.message : error;
  }
};
