import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import TBHeader from '../layouts/TBHeader';
import SearchBox from 'common/components/SearchBox/SearchBox';
import { toastify } from 'common/components/Toastify';
import { useAppContext } from '../../context/AppContext';
import { getAllDisciplines } from '../../services/discipline.service';
import './Discipline.css';

const LeftContent = () => {
  return (
    <div className="left-content">
      <span className="left-span">
        <FormattedMessage id="discipline.steps.3" />
      </span>

      <ul>
        <li>
          <FormattedMessage id="discipline.steps.4" />
        </li>
        <li>
          <FormattedMessage id="discipline.steps.5" />
        </li>
        <li>
          <FormattedMessage id="discipline.steps.6" />
        </li>
      </ul>
    </div>
  );
};

const Discipline = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { dispatchEvent } = useAppContext();

  const [allData, setAllData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState(() => {
    const storedItems = sessionStorage.getItem('selectedDiscipline');
    return storedItems ? JSON.parse(storedItems) : [];
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleNext = () => {
    if (selectedItems.length > 0) {
      sessionStorage.setItem('selectedDiscipline', JSON.stringify(selectedItems));
      navigate(`/booktab`);
    }
    // console.log("selected items discipline ", selectedItems)
  };

  useEffect(() => {
    document.title = 'Choose Your Discipline';
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatchEvent('SHOW_LOADER');
        const apiData = await getAllDisciplines();
        setAllData(apiData);
        setSearchResults(apiData);
      } catch (error) {
        console.error('Error fetching data:', error);
        toastify.showErrorToast(error);
      } finally {
        dispatchEvent('HIDE_LOADER');
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = searchValue => {
    const filteredResults = allData.filter(item => item.toLowerCase().includes(searchValue.toLowerCase()));

    setSearchResults(filteredResults);
  };

  const handleSelectItem = item => {
    setSelectedItems(prevSelectedItems => {
      if (prevSelectedItems.includes(item)) {
        return prevSelectedItems.filter(selectedItem => selectedItem !== item);
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  return (
    <>
      <TBHeader />
      <div className="discipline-container">
        <div className="top-container">
          <h2 className="choose-your-discipline">
            <FormattedMessage id="discipline.steps.2" />
          </h2>
          <button className="discipline btn btn-primary" onClick={handleNext} disabled={selectedItems.length === 0}>
            <FormattedMessage id="message.next" defaultMessage="Next" />
          </button>
        </div>
        <div className="discipline d-flex justify-content-between">
          <LeftContent />
          <div className="discipline search-container">
            <SearchBox placeholder={intl.formatMessage({ id: 'message.searchDiscipline' })} onSearch={handleSearch} />

            {!isLoading && allData.length === 0 && (
              <div className="no-data-message">
                <FormattedMessage id="noDisciplinesMessage" />
              </div>
            )}
            {!isLoading && allData.length && searchResults.length === 0 && (
              <div className="no-matching-discipline-message" tabIndex="0">
                <FormattedMessage id="noMatchingDisciplinesMessage" />
              </div>
            )}
            <ul className="discipline result-list mt-3">
              {searchResults.map((item, index) => (
                <li
                  key={index}
                  tabIndex="0"
                  className={`result-item ${selectedItems.includes(item) ? 'selected' : ''}`}
                  onClick={() => handleSelectItem(item)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') handleSelectItem(item);
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Discipline;
