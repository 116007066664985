import { deepClone } from 'common/utils';
import { QUESTION_TYPES } from 'common/constants/question-constants';

/**
 * Checks if the view button should be enabled for a given quiz type and data.
 *
 * @param {string} quizType - The type of quiz (e.g. Essay, FillInBlanks, Matching, etc.)
 * @param {object} data - The quiz data (e.g. caption, options, blanks, etc.)
 * @returns {boolean} Whether the view button should be enabled
 */
export const isViewButtonEnabled = (quizType, data) => {
  const isCaptionValid = !!data.Caption.trim();

  switch (quizType) {
    case QUESTION_TYPES.Essay:
      return isCaptionValid;
    case QUESTION_TYPES.FillInBlanks:
      return isCaptionValid && data.blanks?.every(blank => blank.answer.trim());
    case QUESTION_TYPES.Matching:
      return isCaptionValid && data.Options.every(opt => opt.option.trim() && opt.matchingOption.trim());
    case QUESTION_TYPES.MultipleChoice:
      return isCaptionValid && data.Options.every(opt => opt && opt.trim());
    case QUESTION_TYPES.MultipleResponse:
      return isCaptionValid && data.optionsRecords.every(opt => opt.value && opt.value.trim());
    case QUESTION_TYPES.TrueFalse:
      return isCaptionValid;
    default:
      throw new Error(`Unsupported quiz type: ${quizType}`);
  }
};

export const filterExtendedMetadata = extendedMetadata => {
  const data = deepClone(extendedMetadata);

  delete data.orientation;
  delete data.expectedLines;

  return data;
};
