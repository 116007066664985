import React, { useEffect } from 'react';
import TestFolder from '../components/TestFolder/TestFolder';

const Tests = () => {
  useEffect(() => {
    document.title = 'Your Tests';
  }, []);
  return (
    <>
      <TestFolder />
    </>
  );
};
export default Tests;
