import { toast } from 'react-toastify';

function Toastify({ type, message }) {
  const options = {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 3000,
    theme: 'colored',
    type: type.toLowerCase(),
  };

  toast(message, options);
}

const options = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
  theme: 'colored',
};

export const toastify = {
  showSuccessToast: message => {
    toast(message, { ...options, type: 'success' });
  },
  showWarningToast: message => {
    toast(message, { ...options, type: 'warning' });
  },
  showErrorToast: message => {
    toast(message, { ...options, type: 'error' });
  },
  showInfoToast: message => {
    toast(message, { ...options, type: 'info' });
  },
};

export default Toastify;
