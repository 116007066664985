import $ from 'jquery';

/**
 * Replaces button elements with blank spaces in a given caption.
 *
 * @param {string} caption - The caption to process.
 * @param {object[]} blanks - An array of objects containing information about the blanks.
 * @returns {string} The processed caption with blank spaces.
 */
export const getPrintModeFbCaption = (Caption, blanks) => {
  try {
    // Remove leading and trailing whitespace from the caption and replace HTML entities with their corresponding characters
    const htmlText = Caption.trim().replaceAll('&amp;nbsp;', ' ').replaceAll('&lt;', '<').replaceAll('&gt;', '>');

    // Create a new paragraph element to hold the caption and append the caption to it
    const $element = $('<p></p>');
    $element.append(htmlText);

    // Find all button elements in the caption
    $element.find('button').each(function (index, button) {
      // Get the blank space HTML for the current button
      const blankSpace = getBlankSpace(blanks[index].blankSpace);
      // Replace the button with the blank space
      $(button).replaceWith(blankSpace);
    });

    // Return the processed caption as a string
    return $element[0].innerHTML;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Returns a blank space HTML string based on the given size.
 *
 * @param {number} size - The size of the blank space.
 * @returns {string} The blank space HTML string.
 */

const getBlankSpace = size => {
  return `<span class="blank-line">${'_'.repeat(size)}</span>`;
};

/**
 * Extracts FIB (Fill-in-the-blank) blanks from a given HTML text.
 *
 * @param {string} text - The HTML text to extract blanks from.
 * @param {object[]} existingAnswers - An array of existing answers.
 * @returns {object[]} An array of blank objects.
 */
export const getFIBBlanks = (text, existingAnswers) => {
  // Parse the HTML text into a jQuery object
  const $domContent = $($.parseHTML(text));
  // Find all button elements in the parsed HTML
  const $buttons = $domContent.find('button');

  const blanks = []; // Initialize an empty array to store the blank objects

  // Iterate over each button element
  $buttons.each((index, button) => {
    // Create a jQuery object for the current button
    const $button = $(button);

    // Get the data-key attribute from the button
    const key = $button.attr('data-key');

    // Find an existing answer that matches the current button's key
    const existingBlank = existingAnswers?.find(correctAnswer => correctAnswer.key === key);

    // Create a new blank object with key, name, answer, and blankSpace properties
    blanks.push({
      key,
      name: `RESPONSE_${index + 1}`,
      answer: existingBlank?.answer || '',
      blankSpace: existingBlank?.blankSpace || 20,
    });
  });

  // Return the array of blank objects
  return blanks;
};
