import { FILE_FORMATS } from '../../../constants/export-constants';

/**
 * Generates a file name based on the provided title, format, and whether it's an answers file.
 *
 * @param {string} title - The title of the file
 * @param {object} format - The file format object with properties: value, fileNameDescription, and fileExtension
 * @param {boolean} isAnswers - Whether the file is an answers file
 * @returns {string} The generated file name
 *
 * @example
 * const title = 'My Document';
 * const format = { value: 'doc', fileNameDescription: 'Export', fileExtension: 'doc' };
 * const isAnswers = true;
 * generateFileName(title, format, isAnswers); // Returns "My Document_answers.doc"
 */
export const generateFileName = (title, format, isAnswers) => {
  const { value, fileNameDescription, fileExtension } = format;
  if (format.value === FILE_FORMATS.DOC || value === FILE_FORMATS.PDF) {
    return `${title}${isAnswers ? '_answers' : ''}.${fileExtension}`;
  }

  const dateAndTime = getFormattedDateTime();
  const description = fileNameDescription ? `${fileNameDescription}_` : '';

  return `${title}_${description}${dateAndTime}.${fileExtension}`;
};

/**
 * Returns a formatted date and time string.
 *
 * @returns {string} A formatted date and time string (e.g. "01232022_140530")
 */
function getFormattedDateTime() {
  const now = new Date();

  const padZero = num => (num < 10 ? '0' + num : num);

  const month = padZero(now.getMonth() + 1); // Months are zero-based
  const day = padZero(now.getDate());
  const year = now.getFullYear();

  const hours = padZero(now.getHours());
  const minutes = padZero(now.getMinutes());
  const seconds = padZero(now.getSeconds());

  return `${month}${day}${year}_${hours}${minutes}${seconds}`;
}
