import { Suspense } from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { RawIntlProvider } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { ToastContainer } from 'react-toastify';
import { MultiBackend, getBackendOptions } from '@minoru/react-dnd-treeview';
import WorkSpaceRoutes from 'workspace/routes';
import TestBuilderRoutes from 'test-builder/routes';
import NoPage from './common/components/NoPage';
import Loader from './common/components/loader/Loader';
import { LoaderProvider } from 'common/providers/LoaderProvider';
import { ConfirmDialogProvider } from 'common/components/ConfirmationDialog';
import { intl } from './localization';
import './styles/App.css';
import './common/styles/common.css';

const router = createHashRouter([
  ...TestBuilderRoutes,
  ...WorkSpaceRoutes,
  {
    path: '*',
    element: <NoPage />,
  },
]);

function App() {
  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <RawIntlProvider value={intl}>
        <Suspense fallback={<Loader />}>
          <LoaderProvider>
            <ConfirmDialogProvider>
              <RouterProvider router={router} />
            </ConfirmDialogProvider>
            <ToastContainer />
          </LoaderProvider>
        </Suspense>
      </RawIntlProvider>
    </DndProvider>
  );
}

export default App;
