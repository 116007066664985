import React from 'react';
import DraggableQuestionTemplate from './DraggableQuestion';
import { useAppContext } from '../../context/AppContext';
import { transformQuestionTemplateToQuestion } from 'common/utils/questions-utils';
import { DEFAULT_QUESTION_TEMPLATES } from 'common/utils/question_templates';
import { deepClone } from 'common/utils';
import './QuestionTemplate.css';

const QuestionTemplate = () => {
  const { dispatchEvent } = useAppContext();

  const handleAddQuestion = item => {
    try {
      let copyItem = deepClone(item);
      const newQuestion = transformQuestionTemplateToQuestion(copyItem);

      dispatchEvent('ADD_NEW_QUESTION_TO_TEST', newQuestion);
    } catch (error) {
      console.error('Error adding question:', error);
    }
  };

  return (
    <div className="custom-questions-tab p-2 flex-grow-1">
      <div className="question-template-layout">
        {DEFAULT_QUESTION_TEMPLATES.map((question, index) => (
          <DraggableQuestionTemplate key={index} question={question} handleAdd={handleAddQuestion} />
        ))}
      </div>
    </div>
  );
};

export default QuestionTemplate;
