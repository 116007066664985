import React from 'react';
import { Tree } from '@minoru/react-dnd-treeview';
import DraggableNode from './DraggableNode';
import { useAppContext } from '../../../context/AppContext';

function SimpleSearchTreeView({ filteredTreeData, lastSelectedQuestion, handleAdd, handleNodeClick }) {
  const {
    questionBanksData: { selectedBookIds },
  } = useAppContext();

  return (
    <div className="question-banks-treeview">
      <Tree
        tree={filteredTreeData}
        rootId={0}
        render={(node, { isOpen, onToggle }) => (
          <DraggableNode
            node={node}
            isOpen={isOpen}
            onToggle={onToggle}
            onDataUpdate={handleNodeClick}
            isClicked={selectedBookIds.includes(node.id)}
            lastSelectedQuestion={lastSelectedQuestion}
            handleAdd={handleAdd}
          />
        )}
        initialOpen={true}
      />
    </div>
  );
}

export default SimpleSearchTreeView;
