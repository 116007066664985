import { useState, useEffect, useRef } from 'react';
import { Tree } from '@minoru/react-dnd-treeview';
import { useIntl } from 'react-intl';
import deepEqual from 'deep-equal';
import DraggableNode from './DraggableNode';
import AdvancedSearchTreeView from './AdvancedSearchTreeView';
import SimpleSearchTreeView from './SimpleSearchTreeView';
import { getAllBooks, fetchAllNodesUnderBook, getAllBookNodeSubNodes } from '../../../services/book.service';
import { toastify } from '../../../../common/components/Toastify';
import { useAppContext } from '../../../context/AppContext';
import { generateBooks, generateChapters, loadQuestions } from './utils';
import { isAdvancedSearchApplied } from '../utils';
import './QuestionBanksTreeView.css';

const QuestionBanksTreeView = ({ disciplines, books }) => {
  const intl = useIntl();
  const {
    questionBanksData: { advancedSearchSelection, selectedBookIds, selectedBookNodes, simpleSearchText },
    dispatchEvent,
  } = useAppContext();

  const [treeData, setTreeData] = useState([]);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [addedNodesInTreeData, setAddedNodesInTreeData] = useState(new Set());
  const [isSearchTermPresent, setIsSearchTermPresent] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const [lastSelectedQuestion, setLastSelectedQuestion] = useState(null);

  const treeRef = useRef(null);
  const previousDataRef = useRef({});

  const handleDrop = newTree => {
    setTreeData(newTree);
  };

  const handleAdd = node => {
    dispatchEvent('ADD_NEW_QUESTION_TO_TEST', node);
    setLastSelectedQuestion(node.guid);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (!deepEqual(previousDataRef.current, { disciplines, books })) {
      loadInitialTreeNodes();
      previousDataRef.current = { disciplines, books };
    }
  }, [disciplines, books]);

  useEffect(() => {
    if (simpleSearchText !== '') {
      if (!selectedBookIds.length) {
        toastify.showWarningToast(intl.formatMessage({ id: 'warning.UserMust.SelectBooks' }));
        dispatchEvent('CLEAR_SIMPLE_SEARCH_TEXT');
        return;
      }

      const hasNodeTypes = selectedBookNodes.some(node => node.type === 'node');
      if (!hasNodeTypes) {
        toastify.showWarningToast(intl.formatMessage({ id: 'warning.noContentFound' }));
        return;
      }

      setIsSearchTermPresent(true);
      const filteredData = selectedBookNodes.filter(
        node => node.type !== 'node' || node.text.toLowerCase().includes(simpleSearchText.toLowerCase())
      );
      const parentIDsOfMatchedNodes = new Set(
        filteredData.filter(node => node.type === 'node').map(node => node.parent)
      );
      const finalFilteredData = filteredData.filter(
        node => node.type !== 'book' || (node.type === 'book' && parentIDsOfMatchedNodes.has(node.id))
      );
      const parentIDsOfMatchedBooks = new Set(
        finalFilteredData.filter(node => node.type === 'book').map(node => node.parent)
      );
      let finalFinalFilteredData = finalFilteredData.filter(
        node => node.type !== 'discipline' || (node.type === 'discipline' && parentIDsOfMatchedBooks.has(node.id))
      );

      if (finalFinalFilteredData.length === 0) {
        toastify.showInfoToast(intl.formatMessage({ id: 'info.NoResultsFoundforSearchItem' }));
      }

      finalFinalFilteredData = finalFinalFilteredData.map(node => ({ ...node }));
      setFilteredTreeData(finalFinalFilteredData);
    } else {
      setIsSearchTermPresent(false);
    }
  }, [simpleSearchText, selectedBookNodes]);

  /**
   * Load initial tree nodes by fetching disciplines and their corresponding books.
   * @returns {Promise<void>} A promise that resolves when the tree nodes are loaded.
   */
  const loadInitialTreeNodes = async () => {
    try {
      // Step 1: Create an array of discipline nodes
      const disciplinesNodes = disciplines.map((discipline, i) => ({
        id: i + 1,
        parent: 0,
        droppable: true,
        text: discipline,
        type: 'discipline',
      }));

      // Step 2: Fetch books data for each discipline node concurrently using Promise.all
      const booksForDisciplines = await Promise.all(
        disciplinesNodes.map(disciplineNode => getAllBooks(disciplineNode.text, true))
      );

      // Step 3: generate Book nodes from books data
      let flattenedBooksNodes = [];
      booksForDisciplines.forEach((books, index) => {
        const totalNodesLength = disciplinesNodes.length + flattenedBooksNodes.length;
        flattenedBooksNodes = flattenedBooksNodes.concat(
          generateBooks(books, disciplinesNodes[index].id, totalNodesLength)
        );
      });

      // Step 4: Combine the disciplinesNodes and flattenedBooksNodes arrays
      const finalNodes = [...disciplinesNodes, ...flattenedBooksNodes];

      // Step 5: Update the selectedBookNodes and treeData states with the finalNodes array
      if (!simpleSearchText) {
        dispatchEvent('UPDATE_SELECTED_BOOK_NODES', finalNodes);
      }
      setTreeData(finalNodes);

      // Step 6: Reset the addedNodes state
      setAddedNodesInTreeData(new Set());

      // Step 7: Close all nodes in the tree
      treeRef?.current?.closeAll();
    } catch (error) {
      toastify.showErrorToast(error?.message);
    }
  };

  /**
   * Handles a node click event.
   *
   * @param {object} clickedNode The node that was clicked.
   */
  const handleNodeClick = clickedNode => {
    const isBook = clickedNode.type === 'book';

    if (clickedNode.droppable && clickedNode.type !== 'discipline') {
      const id = isBook ? clickedNode.bookGuid : clickedNode.bookGuid + clickedNode.nodeGuid;

      if (isSearchTermPresent || !addedNodesInTreeData.has(id)) {
        isBook ? loadBookChildren(clickedNode) : loadChildNodesAndQuestions(clickedNode);
      }
    }
  };

  /**
   * Handles the selection of a book node.
   *
   * @param {Object} node - The book node being selected or deselected.
   * @returns {void}
   */
  const handleBookSelection = node => {
    const isAlreadyClicked = selectedBookIds.includes(node.id);

    if (isAlreadyClicked) {
      const newSelectedBookNodes = selectedBookNodes.filter(
        item => !(item.type === 'node' && item.bookGuid === node.bookGuid)
      );
      dispatchEvent('UPDATE_SELECTED_BOOK_NODES', newSelectedBookNodes);
      const newBookIds = selectedBookIds.filter(id => id !== node.id);
      dispatchEvent('UPDATE_SELECTED_BOOK_IDS', newBookIds);
    } else {
      loadBookChildrenNodesAsFlat(node, { flat: 1 });
      dispatchEvent('UPDATE_SELECTED_BOOK_IDS', [...selectedBookIds, node.id]);
    }
  };

  /**
   * Loads the children nodes of a book as a flat array.
   * @param {Object} book - The parent book object.
   * @param {Object} [queryParams={}] - The query parameters for the fetch request.
   *
   * @returns {Promise} A promise that resolves when the nodes have been loaded.
   */
  const loadBookChildrenNodesAsFlat = async (book, queryParams = {}) => {
    try {
      const chapters = await fetchAllNodesUnderBook(book.bookGuid, queryParams);
      const nodeList = generateChapters(chapters, book);

      dispatchEvent('UPDATE_SELECTED_BOOK_NODES', [...selectedBookNodes, ...nodeList]);
    } catch (error) {
      toastify.showErrorToast(error?.message);
    }
  };

  /**
   * Fetches the child nodes of a given book node and adds them to the tree data.
   * @param {Object} node - The book node to fetch child nodes for.
   *
   * @returns {Promise} A promise that resolves when the child nodes have been added to the tree data.
   */
  const loadBookChildren = async node => {
    try {
      const nodes = await fetchAllNodesUnderBook(node.bookGuid);

      const nodeList = nodes.map((item, index) => ({
        id: treeData.length + index + 1,
        parent: node.id,
        droppable: true,
        bookGuid: node.bookGuid,
        nodeGuid: item.guid,
        text: `${item.title}`,
        type: 'node',
      }));

      setTreeData([...treeData, ...nodeList]);
      if (!isSearchTermPresent) {
        setAddedNodesInTreeData(nodes => nodes.add(node.bookGuid));
      }
    } catch (error) {
      toastify.showErrorToast(error?.message);
    }
  };

  /**
   * Loads child nodes for a given node in the tree.
   *
   * @param {object} node - The node for which child nodes are to be loaded.
   *
   * @returns {Promise<void>} A promise that resolves when the child nodes are loaded.
   */
  const loadChildNodes = async node => {
    try {
      const nodes = await getAllBookNodeSubNodes(node.bookGuid, node.nodeGuid);

      const childNodes = nodes.map((childNode, index) => ({
        id: treeData.length + index + 1,
        parent: node.id,
        droppable: true,
        bookGuid: node.bookGuid,
        nodeGuid: childNode.guid,
        text: `${childNode.title}`,
        type: 'node',
      }));

      setTreeData(treeData => [...treeData, ...childNodes]);
      setAddedNodesInTreeData(nodes => nodes.add(node.bookGuid + node.nodeGuid));
    } catch (error) {
      toastify.showErrorToast(error?.message);
    }
  };

  /**
   * Fetches all the child-nodes & questions of a node and updates the state with the fetched data.
   * @param {Object} node - The node object containing bookGuid and nodeGuid.
   */
  const loadChildNodesAndQuestions = async node => {
    dispatchEvent('SHOW_LOADER');
    const questionNodes = await loadQuestions(node);

    isSearchTermPresent
      ? setFilteredTreeData(filteredTreeData => [...filteredTreeData, ...questionNodes])
      : setTreeData(treeData => [...treeData, ...questionNodes]);

    if (!isSearchTermPresent) {
      await loadChildNodes(node);
    }

    dispatchEvent('HIDE_LOADER');
  };

  if (isSearchTermPresent) {
    return (
      <SimpleSearchTreeView
        filteredTreeData={filteredTreeData}
        lastSelectedQuestion={lastSelectedQuestion}
        handleAdd={handleAdd}
        handleNodeClick={handleNodeClick}
      />
    );
  } else if (isAdvancedSearchApplied(advancedSearchSelection)) {
    return <AdvancedSearchTreeView lastSelectedQuestion={lastSelectedQuestion} handleAdd={handleAdd} />;
  } else {
    return (
      <div
        className={`question-banks-treeview ${isDragging ? 'grabbing' : ''}`}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{ marginBottom: '-47px' }}
      >
        <Tree
          ref={treeRef}
          tree={treeData}
          rootId={0}
          render={(node, { isOpen, onToggle }) => (
            <DraggableNode
              node={node}
              isOpen={isOpen}
              onToggle={onToggle}
              onDataUpdate={handleNodeClick}
              onBookSelection={handleBookSelection}
              isClicked={selectedBookIds?.includes(node.id)}
              lastSelectedQuestion={lastSelectedQuestion}
              handleAdd={handleAdd}
            />
          )}
          dragPreviewRender={monitorProps => <div className="custom-drag-preview">{monitorProps.item.text}</div>}
          onDrop={handleDrop}
          dragPreviewClassName="custom-drag-preview"
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          canDrag={() => true}
          canDrop={() => true}
        />
      </div>
    );
  }
};

export default QuestionBanksTreeView;
