import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, NavLink } from 'react-bootstrap';
import { subscribe, unsubscribe } from 'common/utils/events';
import { useAuth } from 'workspace/providers/auth/AuthContext';

const ProfileModal = () => {
  const { userData, logout } = useAuth();

  useEffect(() => {
    subscribe('ws:auth:logout', logout);

    return () => unsubscribe('ws:auth:logout', logout);
  }, []);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={NavLink}>
          <i className="bi bi-person-fill"></i> {userData.firstName} {userData.lastName}
        </Dropdown.Toggle>

        <Dropdown.Menu className="signout-menu" align="end">
          <Dropdown.Item as="button" onClick={handleLogout}>
            <FormattedMessage id="profile.signout" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default ProfileModal;
