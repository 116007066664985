import { EditorState, Modifier, SelectionState } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';
import $ from 'jquery';

/**
 * Inserts a blank into the editor state at the current selection.
 *
 * @param {EditorState} editorState The current editor state.
 * @returns {EditorState} The updated editor state with the inserted blank.
 */
export function insertBlankInState(editorState) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  // if selection is not collapsed do nothing
  if (!selectionState.isCollapsed()) return editorState;

  const currentBlanksCount = getBlanksCount(editorState);

  const key = uuidv4();
  const contentStateWithEntity = contentState.createEntity('BLANK', 'IMMUTABLE', {
    number: currentBlanksCount + 1,
    key,
  });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  // Insert a space before the blank
  const contentStateWithTrailingSpaceBefore = Modifier.insertText(
    contentState,
    selectionState,
    ' ' // Insert an additional space before the blank entity
  );

  // Insert the blank entity into the content
  const contentStateWithBlank = Modifier.insertText(
    contentStateWithTrailingSpaceBefore,
    contentStateWithTrailingSpaceBefore.getSelectionAfter(),
    `Fill Blank`,
    null,
    entityKey
  );

  // Insert a space after the blank for the cursor to move to
  const contentStateWithTrailingSpace = Modifier.insertText(
    contentStateWithBlank,
    contentStateWithBlank.getSelectionAfter(),
    ' ' // Insert an additional space after the blank entity
  );

  // Calculate the new selection state to place the cursor after the inserted blank
  const newSelection = selectionState.merge({
    anchorOffset: selectionState.getAnchorOffset() + 11,
    focusOffset: selectionState.getAnchorOffset() + 11,
  });

  const newEditorState = EditorState.push(editorState, contentStateWithTrailingSpace, 'insert-characters');

  // Apply the new selection state
  const editorStateWithSelection = EditorState.forceSelection(newEditorState, newSelection);

  return updateBlankNumbers(editorStateWithSelection);
}

/**
 * Deletes a blank entity from the editor state and updates the selection.
 *
 * @param {EditorState} editorState - The current editor state.
 * @param {string} uuidKey - The UUID key of the blank entity to delete.
 *
 * @returns {EditorState} The updated editor state with the blank entity deleted.
 */
export const deleteBlankInState = (editorState, uuidKey) => {
  const contentState = editorState.getCurrentContent();
  let newContentState = contentState;
  let selectionToApply;

  contentState.getBlockMap().forEach(block => {
    block.findEntityRanges(
      character => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'BLANK' &&
          contentState.getEntity(entityKey).getData().key === uuidKey
        );
      },
      (start, end) => {
        // Capture the selection to apply after deleting the blank
        selectionToApply = SelectionState.createEmpty(block.getKey()).merge({
          anchorOffset: start,
          focusOffset: start,
        });

        const selectionToDelete = SelectionState.createEmpty(block.getKey()).merge({
          anchorOffset: start,
          focusOffset: end,
        });
        newContentState = Modifier.removeRange(newContentState, selectionToDelete, 'forward');
      }
    );
  });

  let newEditorState = EditorState.push(editorState, newContentState, 'remove-range');

  // Apply the new selection state to place the cursor at the position of the deleted blank
  newEditorState = EditorState.forceSelection(newEditorState, selectionToApply);

  return updateBlankNumbers(newEditorState);
};

/**
 * Returns the count of blanks in the editor state.
 *
 * @param {EditorState} state - The current editor state.
 * @returns {number} The count of blanks.
 */
export const getBlanksCount = state => {
  const contentState = state.getCurrentContent();
  let count = 0;

  contentState.getBlockMap().forEach(block => {
    block.findEntityRanges(
      character => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'BLANK';
      },
      () => {
        count++;
      }
    );
  });

  return count;
};

/**
 * Creates a content state with blanks from a given content state.
 *
 * @param {ContentState} contentState - The content state to create blanks from.
 * @returns {ContentState} The content state with blanks.
 */
export const createContentStateWithBlanks = (contentState, html) => {
  const regex = /Fill Blank/g;
  let blankNumber = 1;
  const buttons = $('<p></p>').html(html).find('button');
  let count = 0;

  contentState.getBlockMap().forEach(block => {
    let blockText = block.getText();
    let match;

    while ((match = regex.exec(blockText)) !== null) {
      const selection = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: match.index,
        focusOffset: match.index + match[0].length,
      });
      const key = $(buttons[count++]).attr('data-key');

      contentState = contentState.createEntity('BLANK', 'IMMUTABLE', { number: blankNumber++, key });
      const entityKey = contentState.getLastCreatedEntityKey();

      contentState = Modifier.replaceText(contentState, selection, match[0], null, entityKey);
    }
  });

  return contentState;
};

/**
 * Updates the numbers of blank entities in the editor state.
 *
 * @param {EditorState} state The current editor state.
 *
 * @returns {EditorState} The updated editor state with blank entities numbered.
 */
export const updateBlankNumbers = state => {
  const contentState = state.getCurrentContent();
  let newContentState = contentState;
  let blankNumber = 1;

  contentState.getBlockMap().forEach(block => {
    block.findEntityRanges(
      character => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === 'BLANK';
      },
      (start, end) => {
        const entityKey = block.getEntityAt(start);
        if (entityKey) {
          newContentState = newContentState.mergeEntityData(entityKey, { number: blankNumber });
          blankNumber++;
        }
      }
    );
  });

  return EditorState.set(state, { currentContent: newContentState });
};

/**
 * Updates the editor selection to avoid placing the cursor within blank entities.
 *
 * @param {EditorState} newEditorState - The new editor state.
 * @returns {EditorState} The updated editor state with the cursor adjusted.
 */
export function updateEditorSelection(newEditorState) {
  const selectionState = newEditorState.getSelection();
  const currentContent = newEditorState.getCurrentContent();
  const blockKey = selectionState.getStartKey();
  const block = currentContent.getBlockForKey(blockKey);
  const startOffset = selectionState.getStartOffset();

  // Check if the cursor is before or after blank entities
  const beforeEntity = block.getEntityAt(Math.max(0, startOffset - 1));
  const afterEntity = block.getEntityAt(startOffset);

  if (isCursorNearBlankEntity(beforeEntity, afterEntity, currentContent)) {
    // Adjust the cursor position to avoid blank entities
    const newSelection = adjustCursorPosition(selectionState, beforeEntity, startOffset);
    newEditorState = EditorState.forceSelection(newEditorState, newSelection);
  }

  return newEditorState;
}

/**
 * Checks if the cursor is near a blank entity.
 *
 * @param {string} beforeEntity - The entity before the cursor.
 * @param {string} afterEntity - The entity after the cursor.
 * @param {ContentState} currentContent - The current content state.
 * @returns {boolean} True if the cursor is near a blank entity, false otherwise.
 */
function isCursorNearBlankEntity(beforeEntity, afterEntity, currentContent) {
  return (
    (beforeEntity && currentContent.getEntity(beforeEntity).getType() === 'BLANK') ||
    (afterEntity && currentContent.getEntity(afterEntity).getType() === 'BLANK')
  );
}

/**
 * Adjusts the cursor position to avoid blank entities.
 *
 * @param {SelectionState} selectionState - The current selection state.
 * @param {string} beforeEntity - The entity before the cursor.
 * @param {number} startOffset - The start offset of the selection.
 * @returns {SelectionState} The updated selection state with the cursor adjusted.
 */
function adjustCursorPosition(selectionState, beforeEntity, startOffset) {
  const anchorOffset = beforeEntity ? startOffset + 1 : startOffset - 1;
  const focusOffset = beforeEntity ? startOffset + 1 : startOffset - 1;
  return selectionState.merge({ anchorOffset, focusOffset });
}
