/**
 * Returns a shuffled copy of the input options array.
 *
 * @param {Object[]} options - The input options array.
 *
 * @returns {Object[]} A shuffled copy of the input options array.
 */
export const getShuffledOptions = options => {
  /**
   * Create a deep copy of the input options array to avoid modifying the original array.
   */
  const shuffledOptions = [...options].map(option => ({ ...option }));

  /**
   * Implement the Fisher-Yates shuffle algorithm to randomize the order of the options.
   */
  for (let i = shuffledOptions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledOptions[i].matchingOption, shuffledOptions[j].matchingOption] = [
      shuffledOptions[j].matchingOption,
      shuffledOptions[i].matchingOption,
    ];
  }

  return shuffledOptions;
};
