import FillInBlanksReadOnly from './FillInBlanksReadOnly';
import FillInBlanksEditView from './FillInBlanksEditView';

const FillInBlanks = props => {
  const { questionNode } = props;

  return questionNode?.qtiModel?.isInEditView ? (
    <FillInBlanksEditView {...props} />
  ) : (
    <FillInBlanksReadOnly {...props} />
  );
};

export default FillInBlanks;
