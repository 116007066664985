import React, { useEffect } from 'react';
import YourQuestions from '../components/YourQuestions/YourQuestions';

const YourQuestionsPage = () => {
  useEffect(() => {
    document.title = 'Your Questions';
  }, []);

  return (
    <>
      <YourQuestions />
    </>
  );
};

export default YourQuestionsPage;
