import MultipleChoiceEditView from './MultipleChoiceEditView';
import MultipleChoiceReadOnly from './MultipleChoiceReadOnly';

const MultipleChoice = props => {
  const { questionNode } = props;

  return questionNode?.qtiModel?.isInEditView ? (
    <MultipleChoiceEditView {...props} />
  ) : (
    <MultipleChoiceReadOnly {...props} />
  );
};

export default MultipleChoice;
