import axios from './axios';
import { extractErrorDetails } from 'common/utils';
import { ROLES } from 'workspace/constants';

/**
 * Creates a new user with the provided data.
 *
 * @param {Object} data - The user data to be sent to the server.
 *
 * @returns {Promise<Object>} - A promise that resolves to the created user data.
 * @throws {Error} - An error with a user-friendly error message if the creation fails.
 *
 */
export async function createUser(data) {
  try {
    const response = await axios.post('ws/users', data);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function updateUser(userId, data) {
  try {
    const response = await axios.put(`/ws/users/${userId}`, data);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function getUsers(role, userId) {
  try {
    const endpoint = `ws/users${role === ROLES.ADMIN ? '?createdBy=' + userId : ''}`;

    const response = await axios.get(endpoint);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function deleteUserFromWorkspace(userId) {
  try {
    const endpoint = `ws/users/${userId}`;
    await axios.delete(endpoint);
  } catch (error) {
    throw extractErrorDetails(error);
  }
}
