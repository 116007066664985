import axios from './axios';
import { extractErrorDetails } from 'common/utils';

/**
 * Adds a new book to the server.
 *
 * @param {Object} bookData - The book data to be added.
 * @returns {Promise<Object>} - A promise that resolves to the added book's data.
 * @throws {Error} - An error with a user-friendly error message if the addition fails.
 */
export async function addBook(bookData) {
  try {
    const response = await axios.post('ws/addBook', bookData);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function updateBook(bookId, bookData) {
  try {
    const response = await axios.put(`/ws/books/${bookId}`, bookData);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function getBooks() {
  try {
    const response = await axios.get('ws/books');
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}

export async function deleteBook(bookId) {
  try {
    const response = await axios.delete(`/ws/books/${bookId}`);
    return response?.data;
  } catch (error) {
    throw extractErrorDetails(error);
  }
}
