import QuestionMapper from '../../components/questions/QuestionMapper';
import {
  getUserQuestionFolders,
  getUserQuestionFoldersRoot,
  getUserQuestions,
} from '../../services/userfolder.service';
import { constructQuestionObject } from '../../../common/utils/questions-utils';

export const fetchRootFolderGuid = async () => {
  try {
    return await getUserQuestionFoldersRoot();
  } catch (error) {
    console.error('Error fetching root folder:', error);
  }
};

export const fetchRootLevelQuestions = async rootQuestionFolderGuid => {
  try {
    if (rootQuestionFolderGuid) {
      const fetchedQuestions = await getUserQuestions(rootQuestionFolderGuid);

      return fetchedQuestions.map(question => constructQuestionObject(question));
    }
  } catch (error) {
    console.error('Error fetching questions:', error);
  }
};

export const fetchUserRootLevelFolders = async () => {
  try {
    const folders = await getUserQuestionFolders();

    return folders.slice().sort((a, b) => b.sequence - a.sequence);
  } catch (error) {
    console.error('Error fetching user folders:', error);
  }
};

const constructFolders = (folders, parent) => {
  if (!folders) return [];

  return folders.map(folder => {
    const { guid: id, sequence, title, children } = folder;

    return {
      parent,
      id,
      sequence,
      text: title,
      droppable: true,
      isQuestion: false,
      questionBindings: folder.questionBindings,
      isChildrenLoaded: !!children,
    };
  });
};

const constructQuestions = (questions, parent) => {
  if (!questions) return [];

  return questions.map((question, index) => {
    return {
      parent,
      id: question.guid,
      text: <QuestionMapper key={question.guid} questionNode={question} questionNodeIndex={index} />,
      isQuestion: true,
      data: question,
      droppable: false,
    };
  });
};

export const generateTreeDataFromTree = (tree, parent = 0) => {
  const { folders, questions } = tree;
  const data = [];

  const foldersData = constructFolders(folders, parent);
  const questionsData = constructQuestions(questions, parent);

  data.push(...foldersData, ...questionsData);

  for (let folder of folders) {
    if (folder.children && Object.keys(folder.children).length) {
      data.push(...generateTreeDataFromTree(folder.children, folder.guid));
    }
  }

  return data;
};

const convertToFlatArray = folders => {
  if (!folders) return [];

  const flatArray = [];

  for (let folder of folders) {
    flatArray.push(folder);

    if (folder.children) {
      flatArray.push(...convertToFlatArray(folder.children.folders));
    }
  }

  return flatArray;
};

export const findSelectedFolder = (folders, id) => {
  // find node in tree stucture
  const flatArray = convertToFlatArray(folders);

  return flatArray.find(folder => folder.guid === id);
};

export const isSourceIsParentOfTarget = (treeDataAsArray, sourceId, targetId) => {
  let parent = targetId;

  while (parent !== 0) {
    if (parent === sourceId) {
      return true;
    }
    parent = treeDataAsArray.find(node => node.id === parent).parent;
  }

  return false;
};
