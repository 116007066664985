import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Modal } from 'react-bootstrap';
import PrintTestOptions from './PrintTestOptions';
import PrintPreview from './PrintPreview';
import { useAppContext } from '../../../context/AppContext';
import { ANSWER_AREAS } from '../../../constants/print-constants';
import './PrintTestModalpopup.css'; // Import CSS file for styling

function PrintTestModalpopup({ show, handleCloseModal }) {
  const printableContentRef = useRef();
  const { selectedTest } = useAppContext();
  const [options, setOptions] = useState({
    answerArea: ANSWER_AREAS.NONE,
    lineBreaks: {},
    lineBreakAt: 1,

    addStudentName: false,
  });

  const handlePrint = useReactToPrint({
    content: () => printableContentRef.current,
    documentTitle: selectedTest.title,
  });

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={handleCloseModal}
      dialogClassName="print-preview-modal"
      size="xl"
      centered
      style={{ maxHeight: '100vh' }}
    >
      <Modal.Body className="p-0 h-100">
        <div className="d-flex h-100">
          <div className="print-preview-left">
            <PrintTestOptions
              options={options}
              maxQuestions={selectedTest?.questions?.length || 0}
              handlePrint={handlePrint}
              handleCloseModal={handleCloseModal}
              setOptions={setOptions}
            />
          </div>
          <div className="print-preview-right">
            <PrintPreview
              ref={printableContentRef}
              className="print-content"
              options={options}
              questions={selectedTest?.questions}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PrintTestModalpopup;
