import React from 'react';
import { Modal, ListGroup, Badge, Table } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { BOOK_STATUS } from 'workspace/constants';
import publishHistory from 'workspace/data/publishHistory';

const bookStatusMap = {
  [BOOK_STATUS.NEW]: { badge: 'info', id: 'ws.status.new', defaultMessage: 'New' },
  [BOOK_STATUS.PUBLISHED]: { badge: 'success', id: 'ws.published', defaultMessage: 'Published' },
  [BOOK_STATUS.REPUBLISHED]: { badge: 'warning', id: 'ws.republished', defaultMessage: 'Republished' },
  [BOOK_STATUS.UNPUBLISHED]: { badge: 'secondary', id: 'ws.unpublished', defaultMessage: 'Unpublished' },
};

const getStatusBadge = status => {
  const { badge = 'light', id = 'ws.status.unknown', defaultMessage = 'Unknown Status' } = bookStatusMap[status] || {};

  return (
    <Badge bg={badge}>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </Badge>
  );
};

const BookDetailsModal = ({ book, handleClose }) => {
  if (!book) return null;

  return (
    <Modal show={true} onHide={handleClose} centered size="lg">
      <Modal.Header className="modal-custom-header">
        <Modal.Title>
          <FormattedMessage id="ws.bookdetails" defaultMessage="Book Details" />
        </Modal.Title>
        <i title="Close" className="bi bi-x-lg" onClick={handleClose}></i>
      </Modal.Header>
      <Modal.Body className="overflow-y-auto">
        <ListGroup variant="flush">
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.booktitle" defaultMessage="Book Title" />:
            </strong>
            &nbsp;
            {book.title}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.bookdescription" defaultMessage="Description" />:
            </strong>
            &nbsp;
            {book.description}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.author" defaultMessage="Author" />:
            </strong>
            &nbsp;
            {book.authors.map((author, index) => (
              <span key={index}>
                {author}
                {index < book.authors.length - 1 && ', '}
              </span>
            ))}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.createdby" defaultMessage="Created By" />:
            </strong>
            &nbsp;
            {book.creatorName}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.createddate" defaultMessage="Created Date" />:
            </strong>
            &nbsp;
            {new Date(book.created).toLocaleDateString()}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.discipline" defaultMessage="Discipline" />:
            </strong>
            &nbsp;
            {book.discipline}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.publisher" defaultMessage="Publisher" />:
            </strong>
            &nbsp;
            {book.publisher}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.status" defaultMessage="Status" />:
            </strong>
            &nbsp;
            {getStatusBadge(book.status)}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>
              <FormattedMessage id="ws.bookhistory" defaultMessage="Book History" />:
            </strong>
          </ListGroup.Item>
        </ListGroup>

        <Table className="table-header" striped bordered hover size="sm">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="ws.publishedBookID" defaultMessage="Published BookID" />
              </th>
              <th>
                <FormattedMessage id="ws.publishedDate" defaultMessage="Published Date" />
              </th>
              <th>
                <FormattedMessage id="ws.publishedBy" defaultMessage="Published By" />
              </th>
            </tr>
          </thead>
          <tbody>
            {publishHistory && publishHistory.length > 0 ? (
              publishHistory.map((record, index) => (
                <tr key={index}>
                  <td>{record['Published BookID']}</td>
                  <td>{record['Published Date']}</td>
                  <td>{record['Published By']}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  <FormattedMessage id="ws.noHistory" defaultMessage="No history available" />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default BookDetailsModal;
