import { FILTER_KEYS_MAP } from '../../../common/constants/question-constants';

/**
 * Checks if an advanced search has been applied based on the provided selection.
 *
 * @param {Object} selection - An object containing the search selection criteria.
 * @param {Array<string>} selection.questionTypes - An array of question types to filter by.
 * @param {string} [selection.filterBy] - The field to filter by.
 * @param {string} [selection.filterByValue] - The value to filter by.
 *
 * @returns {boolean} True if an advanced search has been applied, false otherwise.
 */
export function isAdvancedSearchApplied(selection) {
  if (!selection) return false;

  const { questionTypes, filterBy, filterByValue } = selection;
  return questionTypes.length > 0 || (filterBy && filterByValue);
}

/**
 * Constructs search query parameters based on the provided selection.
 *
 * @param {Object} selection - An object containing the search selection criteria.
 * @param {Array<string>} selection.questionTypes - An array of question types to filter by.
 * @param {string} [selection.filterBy] - The field to filter by.
 * @param {string} [selection.filterByValue] - The value to filter by.
 *
 * @returns {Object} An object containing the search query parameters.
 */
export function constructSearchQueryParams(selection) {
  if (!isAdvancedSearchApplied(selection)) return {};

  const { questionTypes, filterBy, filterByValue } = selection;
  const queryParams = {};

  // Use a more concise way to add questionTypes to queryParams
  if (questionTypes.length > 0) {
    queryParams.QTyp = questionTypes.join(',');
  }

  if (filterBy && filterByValue) {
    queryParams[FILTER_KEYS_MAP[filterBy]] = btoa(filterByValue);
  }

  return queryParams;
}
