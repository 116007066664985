/**
 * Enum for user roles.
 *
 * @enum {number}
 */
export const ROLES = Object.freeze({
  SUPER_ADMIN: 1, // Super admin role.
  ADMIN: 2, // Admin role.
  AUTHOR: 3, // Author role.
});

export const USER_ROLE_INFO = Object.freeze({
  [ROLES.SUPER_ADMIN]: {
    title: 'Super Admin',
    iconClass: 'fa-user-tie',
  },
  [ROLES.ADMIN]: {
    title: 'Admin',
    iconClass: 'fa-user',
  },
  [ROLES.AUTHOR]: {
    title: 'Author',
    iconClass: 'fa-user-pen',
  },
});

/**
 * Defines the access control for each role.
 *
 * @type {Object.<number, Object>}
 */
export const ROLE_ACCESS = {
  // Access control for Super Admin role.
  [ROLES.SUPER_ADMIN]: {
    canManageUsers: true,
    canManageBooks: true,
  },
  // Access control for Admin role.
  [ROLES.ADMIN]: {
    canManageUsers: true,
    canManageBooks: true,
  },
  // Access control for Author role.
  [ROLES.AUTHOR]: {
    canManageUsers: false,
    canManageBooks: true,
  },
};

export const BOOK_STATUS = Object.freeze({
  NEW : 0,
  PUBLISHED : 1,
  REPUBLISHED : 2,
  UNPUBLISHED : 3
})
