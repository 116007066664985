import React from 'react';
import { FormattedMessage } from 'react-intl';
import './QuestionBanksTips.css';

const QuestionBanksTips = () => {
  return (
    <div className="question-banks-tips-container">
      <div className="flex-box">
        <i className="far fa-lightbulb light-orange" aria-label="bulb icon"></i>

        <div className="question-instructions-container">
          <p className="bold-text">
            <FormattedMessage id="questionbanks.instructions.edit" />
          </p>
          <p className="bold-text">
            <FormattedMessage id="questionbanks.instructions.create" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionBanksTips;
