import Test from '../entities/Test.Entity';
import { getAdjacentTest } from '../utils/test-utils';
import { publish } from 'common/utils/events';
import { rearrangeQuestions, updateQuestionWithItemId } from 'common/utils/questions-utils';
import { deepClone } from 'common/utils';

function TestTabsReducer(state, action) {
  const { type, payload } = action;
  const { tests, testsMasterData, selectedTest } = state;

  switch (type) {
    case 'INITIALIZE_DEFAULT_TAB':
      if (!tests || tests?.length === 0) {
        const defaultTestTab = new Test();
        defaultTestTab.title = 'Untitled 1';

        return {
          tests: [...tests, defaultTestTab],
          testsMasterData: [...testsMasterData, deepClone(defaultTestTab)],
          selectedTest: defaultTestTab,
        };
      }

      return state;

    case 'UPDATE_SELECTED_TEST_TITLE': {
      const { title } = payload;

      return {
        ...state,
        selectedTest: { ...selectedTest, title: title },
        tests: tests.map(test => (test.id === selectedTest.id ? { ...test, title: title } : test)),
      };
    }

    case 'ADD_NEW_TEST':
      return {
        ...state,
        selectedTest: payload,
        tests: [payload, ...tests],
        testsMasterData: [deepClone(payload), ...testsMasterData],
      };

    case 'ACTIVATE_TEST_TAB': {
      const selectedItemIndex = tests.findIndex(test => test.id === payload.id);
      const updatedTests = [...tests];

      let selectedItem = tests[selectedItemIndex];
      // move selected test to the beggining if it is selected from the dropdown
      if (selectedItemIndex > 3) {
        selectedItem = updatedTests.splice(selectedItemIndex, 1)[0];
        updatedTests.unshift(selectedItem);
      }

      // this will publish an event to move the scroll position to top
      publish('active_test_changed');

      return {
        ...state,
        selectedTest: selectedItem,
        tests: updatedTests,
      };
    }

    case 'UPDATE_SELECTED_TEST_AFTER_SAVE': {
      return {
        selectedTest: payload,
        tests: tests.map(test => (test.id === selectedTest.id ? payload : test)),
        testsMasterData: testsMasterData.map(test => (test.id === selectedTest.id ? deepClone(payload) : test)),
      };
    }

    case 'UPDATE_TESTS_AFTER_FOLDER_DELETE': {
      return {
        ...state,
        tests: payload,
      };
    }

    case 'REMOVE_TEST_FROM_TABS': {
      const newSelectedTest = getAdjacentTest(tests, payload, selectedTest);

      return {
        selectedTest: newSelectedTest || tests[0],
        tests: tests.filter(test => test.id !== payload.id),
        testsMasterData: testsMasterData.filter(test => test.id !== payload.id),
      };
    }

    case 'UPDATE_QUESTION_INSIDE_SELETED_TEST': {
      const { question, index } = payload;

      const questions = [...selectedTest.questions];
      questions[index] = question;

      return {
        ...state,
        selectedTest: { ...selectedTest, questions },
        tests: tests.map(test => (test.id === selectedTest.id ? { ...test, questions } : test)),
      };
    }

    case 'INSERT_QUESTION_AT_END': {
      const { question, isDuplicate = false } = payload;

      const updatedTest = { ...selectedTest };
      updatedTest.questions = [...updatedTest.questions, updateQuestionWithItemId(question, isDuplicate)];

      return {
        ...state,
        selectedTest: updatedTest,
        tests: tests.map(test => (test.id === selectedTest.id ? { ...test, questions: updatedTest.questions } : test)),
      };
    }

    case 'EDIT_QUESTION_CLICK': {
      const { index } = payload;

      const updatedTest = { ...selectedTest };
      updatedTest.questions[index].qtiModel.isInEditView = true;
      updatedTest.questions[index] = Object.assign({}, updatedTest.questions[index]);
      updatedTest.questions = [...updatedTest.questions];

      return {
        ...state,
        selectedTest: updatedTest,
        tests: tests.map(test => (test.id === selectedTest.id ? { ...test, questions: updatedTest.questions } : test)),
      };
    }

    case 'DELETE_QUESTION_CLICK': {
      const { index } = payload;

      const updatedTest = { ...selectedTest };
      updatedTest.questions.splice(index, 1);
      updatedTest.questions = [...updatedTest.questions];

      return {
        ...state,
        selectedTest: updatedTest,
        tests: tests.map(test => (test.id === selectedTest.id ? { ...test, questions: updatedTest.questions } : test)),
      };
    }

    case 'REARRANGE_QUESTIONS': {
      // Extract the drag source ID and drop target ID from the payload
      const { dragSourceId, dropTargetId } = payload;

      // Create a copy of the current questions array
      const questions = [...selectedTest.questions];

      // Call the rearrangeQuestions function to update the questions array
      const updatedQuestions = rearrangeQuestions(questions, dragSourceId, dropTargetId);

      return {
        ...state,
        // Update the selected test with the new questions array
        selectedTest: { ...selectedTest, questions: updatedQuestions },
        // Update selected test in the tests array so that questions rearrangements persists on tab changes
        tests: tests.map(test => (test.id === selectedTest.id ? { ...test, questions: updatedQuestions } : test)),
      };
    }

    case 'SELECT_FOLDER_WHILE_SAVE':
      return {
        ...state,
        selectedFolderWhileSave: payload,
      };

    case 'CLEAR_SELECTED_FOLDER_IN_SAVE_AS_POPUP':
      return {
        ...state,
        selectedFolderWhileSave: null,
      };

    default:
      return state;
  }
}

export default TestTabsReducer;
